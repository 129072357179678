/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Month } from './Month';
import {
    MonthFromJSON,
    MonthFromJSONTyped,
    MonthToJSON,
} from './Month';

/**
 * A customer invoice for a given year/month period.
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    year: number;
    /**
     * 
     * @type {Month}
     * @memberof Invoice
     */
    month: Month;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    customerId: string;
    /**
     * The invoice may be in multiple states depending on whether it is fully computed and paid.
     * When the state is 'GENERATING' the invoice is currently being computed and should be ready shortly.
     * Once it enters the 'PREVIEWING' state it can be considered final but it has not yet been paid.
     * Finally it may enter the 'CLOSED' state, this state denotes that the invoice has been paid and should be considered final.
     * @type {string}
     * @memberof Invoice
     */
    state: InvoiceStateEnum;
}


/**
 * @export
 */
export const InvoiceStateEnum = {
    Generating: 'GENERATING',
    Previewing: 'PREVIEWING',
    Closed: 'CLOSED'
} as const;
export type InvoiceStateEnum = typeof InvoiceStateEnum[keyof typeof InvoiceStateEnum];


/**
 * Check if a given object implements the Invoice interface.
 */
export function instanceOfInvoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "state" in value;

    return isInstance;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'year': json['year'],
        'month': MonthFromJSON(json['month']),
        'customerId': json['customerId'],
        'state': json['state'],
    };
}

export function InvoiceToJSON(value?: Invoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'year': value.year,
        'month': MonthToJSON(value.month),
        'customerId': value.customerId,
        'state': value.state,
    };
}

