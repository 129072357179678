/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostPlanCostOverrideIccRange } from './CostPlanCostOverrideIccRange';
import {
    CostPlanCostOverrideIccRangeFromJSON,
    CostPlanCostOverrideIccRangeFromJSONTyped,
    CostPlanCostOverrideIccRangeToJSON,
} from './CostPlanCostOverrideIccRange';

/**
 * Details ICC ranges that the cost plan applies to, this is only relevant for cost overrides.
 * @export
 * @interface CostPlanCostOverride
 */
export interface CostPlanCostOverride {
    /**
     * 
     * @type {CostPlanCostOverrideIccRange}
     * @memberof CostPlanCostOverride
     */
    iccRange: CostPlanCostOverrideIccRange;
}

/**
 * Check if a given object implements the CostPlanCostOverride interface.
 */
export function instanceOfCostPlanCostOverride(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "iccRange" in value;

    return isInstance;
}

export function CostPlanCostOverrideFromJSON(json: any): CostPlanCostOverride {
    return CostPlanCostOverrideFromJSONTyped(json, false);
}

export function CostPlanCostOverrideFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostPlanCostOverride {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iccRange': CostPlanCostOverrideIccRangeFromJSON(json['iccRange']),
    };
}

export function CostPlanCostOverrideToJSON(value?: CostPlanCostOverride | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iccRange': CostPlanCostOverrideIccRangeToJSON(value.iccRange),
    };
}

