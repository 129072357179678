import React, { useMemo } from "react";
import { CobiraTable, Country, CountryInfo, getCountry } from "@cobira/ui-library";
import { TelcoCost } from "../../api";
import { createColumnHelper } from "@tanstack/react-table";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import TelcoCostPrice from "../TelcoCostPrice/TelcoCostPrice";
import { DEFAULT_COST_CURRENCY, TelcoServiceType } from "../../utils/pricingUtils";
import { TelcoCostCsvConfig } from "../../utils/csv/telcoCostCsvConfig";

const telcoCostColumn = createColumnHelper<TelcoCost>();
const COLUMNS = () => [
    telcoCostColumn.accessor("countryCode", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue()) as Country} withFlag />,
    }),
    telcoCostColumn.accessor("plmn", {
        id: "plmn",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    telcoCostColumn.accessor(row => row, {
        id: "dataCost",
        header: TelcoCostCsvConfig.columnConfig.byteCost,
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <TelcoCostPrice
                cost={row.getValue().byteCost || 0}
                currency={DEFAULT_COST_CURRENCY}
                serviceType={TelcoServiceType.Data}
            />
        ),
    }),
    telcoCostColumn.accessor(row => row, {
        id: "smsMoCost",
        header: TelcoCostCsvConfig.columnConfig.mobileOriginatedCost,
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <TelcoCostPrice
                cost={row.getValue().mobileOriginatedCost || 0}
                currency={DEFAULT_COST_CURRENCY}
                serviceType={TelcoServiceType.SmsMobileOriginated}
            />
        ),
    }),
    telcoCostColumn.accessor(row => row, {
        id: "smsMtCost",
        header: TelcoCostCsvConfig.columnConfig.mobileTerminatedCost,
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <TelcoCostPrice
                cost={row.getValue().mobileTerminatedCost || 0}
                currency={DEFAULT_COST_CURRENCY}
                serviceType={TelcoServiceType.SmsMobileTerminated}
            />
        ),
    }),
    telcoCostColumn.accessor(row => row, {
        id: "networkAccessCost",
        header: TelcoCostCsvConfig.columnConfig.networkAccessCost,
        meta: {
            isNumeric: true,
        },
        cell: row => (
            <TelcoCostPrice
                cost={row.getValue().networkAccessCost || 0}
                currency={DEFAULT_COST_CURRENCY}
                serviceType={"IMSI"}
            />
        ),
    }),
];

export interface TelcoCostTableWithSearchProps {
    telcoCosts: TelcoCost[];
    isLoading?: boolean;
}

const TelcoCostTable = ({ telcoCosts, isLoading }: TelcoCostTableWithSearchProps) => {
    const columns = useMemo(() => COLUMNS(), []);

    return <CobiraTable columns={columns} data={telcoCosts || []} isLoading={isLoading} withPagination />;
};

export default TelcoCostTable;
