import { CastingContext } from "csv-parse/browser/esm/sync";

export const TelcoCostCsvConfig = {
    columnConfig: {
        countryCode: "Country",
        plmn: "PLMN",
        byteCost: "MB Cost",
        mobileOriginatedCost: "SMS MO Cost",
        mobileTerminatedCost: "SMS MT Cost",
        networkAccessCost: "Network Access Cost",
        currency: "Currency",
    },
    columnParseConfig: (header: string[]) => {
        const mappings = {
            [TelcoCostCsvConfig.columnConfig.countryCode]: "countryCode",
            [TelcoCostCsvConfig.columnConfig.plmn]: "plmn",
            [TelcoCostCsvConfig.columnConfig.byteCost]: "byteCost",
            [TelcoCostCsvConfig.columnConfig.mobileOriginatedCost]: "mobileOriginatedCost",
            [TelcoCostCsvConfig.columnConfig.mobileTerminatedCost]: "mobileTerminatedCost",
            [TelcoCostCsvConfig.columnConfig.networkAccessCost]: "networkAccessCost",
            [TelcoCostCsvConfig.columnConfig.currency]: "currency",
        };
        return header.map(column => mappings[column] ?? column);
    },
    columnParseCastConfig: (value: string, context: CastingContext) => {
        if (context.header) {
            return value;
        }
        const numberColumns = ["byteCost", "mobileTerminatedCost", "mobileOriginatedCost", "networkAccessCost"];
        if (context.column as string) {
            if (numberColumns.includes(context.column as string)) {
                if (context.column === "byteCost") {
                    return parseFloat(value) / 1000 / 1000;
                }
                return parseFloat(value);
            }
        }

        return value;
    },
    delimiter: ",",
};
