import React, { useCallback } from "react";
import { ConfirmModal, useNotifications } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Text } from "@chakra-ui/react";
import { Customer, UsagePackageType } from "../../../api";

export interface ConfirmDeleteCustomerPackageCatalogueModalProps {
    onClose: () => void;
    usagePackageType?: UsagePackageType;
    customer?: Customer;
}

const ConfirmDeleteCustomerPackageCatalogueModal = ({
    onClose,
    usagePackageType,
    customer,
}: ConfirmDeleteCustomerPackageCatalogueModalProps) => {
    const { customerApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const deleteCatalogueEntry = useMutation<void, Error, UsagePackageType>(
        ["customers", customer?.id, "usagepackagetypes"],
        (usagePackageType: UsagePackageType) => {
            return customerApi.deleteCustomerUsagePackageType({
                customerId: customer?.id || "",
                usagePackageTypeId: usagePackageType.id,
            });
        },
    );
    const handleOnClose = useCallback(
        (confirmed: boolean) => {
            if (confirmed && usagePackageType?.id && customer?.id) {
                deleteCatalogueEntry.mutate(usagePackageType, {
                    onSuccess: () => {
                        notifications.success("Bundle disabled for customer");
                        queryClient.invalidateQueries(["customers", customer?.id, "usagepackagetypes"]);
                    },
                });
            }
            onClose();
        },
        [customer?.id, deleteCatalogueEntry, notifications, onClose, queryClient, usagePackageType],
    );

    return (
        <ConfirmModal
            onClose={handleOnClose}
            isOpen={!!customer && !!usagePackageType}
            title={"Disable Bundle for Customer"}
            description={
                <Container>
                    <Text>Are you sure you want to disable the usage of the bundle for the customer?</Text>
                </Container>
            }
        />
    );
};

export default ConfirmDeleteCustomerPackageCatalogueModal;
