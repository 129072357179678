import React from "react";
import {
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    usePersistedSearch,
} from "@cobira/ui-library";
import { TabPanel, VStack } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import SimConfigurationTable from "../../../components/SimConfigurationTable/SimConfigurationTable";
import { NetworkCredentialType, SimCardCapabilityName, SimConfiguration } from "../../../api";
import { BooleanLabels } from "../../../labels/BooleanLabels";
import { SimCardCapabilityNameLabels } from "../../../labels/SimCardCapabilityNameLabels";
import { SearchSelectNetworkCredentialTypeInput } from "../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";
import { mapBooleanFilter } from "../../../utils/mapperUtils";

const SimConfigurationListPanel = () => {
    const { configurationApi } = useApi();
    const { pageState, setPageState, resetPageState } = usePagination({ pageIndex: 0, pageSize: 20 });

    const search = usePersistedSearch({
        persistenceKey: "sim-configuration-search",
        onChange: resetPageState,
        config: {
            search: singleInput<string>("Search"),
            includeDeleted: singleInput<boolean>("Include Deleted", { display: BooleanLabels }),
            simCapability: singleInput<SimCardCapabilityName>("SIM Capability", {
                display: value => SimCardCapabilityNameLabels[value],
            }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type", {
                display: value => `${value.imsiProvider} (${value.plmn})`,
            }),
        },
    });

    const { navigate } = usePageNavigation<SimConfiguration>({
        route: value => `/configuration/simconfigurations/${value.id}`,
    });
    const simConfigurationQuery = useQuery(["simconfigurations", pageState, search.state], () =>
        configurationApi.getSimConfigurations({
            pageSize: pageState.pageSize,
            pageNumber: pageState.pageIndex,
            includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
            search: search.state?.search?.value || undefined,
            simCapability: search.state?.simCapability?.value || undefined,
            networkCredentialTypeSearch: search.state?.networkCredentialType?.value?.id || undefined,
        }),
    );

    return (
        <TabPanel w={"100%"}>
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Search by name"}
                                />
                            ),
                        },
                        {
                            id: "includeDeleted",
                            menuLabel: "Include Deleted",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "includeDeleted" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "simCapability",
                            menuLabel: "SIM Capability",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "simCapability" })}
                                    items={Object.values(SimCardCapabilityName)}
                                    autocompleteAbleMapping={value => SimCardCapabilityNameLabels[value]}
                                    displayMapping={value => SimCardCapabilityNameLabels[value]}
                                />
                            ),
                        },
                        {
                            id: "networkCredentialType",
                            menuLabel: "Network Credential Type",
                            inputComponent: (
                                <SearchSelectNetworkCredentialTypeInput
                                    registration={search.registerInput({ id: "networkCredentialType" })}
                                />
                            ),
                        },
                    ]}
                />
                <SimConfigurationTable
                    simConfigurationQuery={simConfigurationQuery}
                    pagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: simConfigurationQuery?.data?.pageProperties?.totalElements || 0,
                        totalPageCount: simConfigurationQuery?.data?.pageProperties?.totalPages || 0,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigate,
                    }}
                />
            </VStack>
        </TabPanel>
    );
};

export default SimConfigurationListPanel;
