/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsumptionState } from './ConsumptionState';
import {
    ConsumptionStateFromJSON,
    ConsumptionStateFromJSONTyped,
    ConsumptionStateToJSON,
} from './ConsumptionState';
import type { NetworkState } from './NetworkState';
import {
    NetworkStateFromJSON,
    NetworkStateFromJSONTyped,
    NetworkStateToJSON,
} from './NetworkState';
import type { SimCardCapabilityName } from './SimCardCapabilityName';
import {
    SimCardCapabilityNameFromJSON,
    SimCardCapabilityNameFromJSONTyped,
    SimCardCapabilityNameToJSON,
} from './SimCardCapabilityName';

/**
 * 
 * @export
 * @interface SimCardActionRequestFilter
 */
export interface SimCardActionRequestFilter {
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    iccLowerBound?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    iccUpperBound?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    simConfigurationId?: string | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof SimCardActionRequestFilter
     */
    billingGroupIdSet?: Set<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    search?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    msisdnSearch?: string | null;
    /**
     * 
     * @type {NetworkState}
     * @memberof SimCardActionRequestFilter
     */
    networkState?: NetworkState;
    /**
     * 
     * @type {Set<string>}
     * @memberof SimCardActionRequestFilter
     */
    iccSet?: Set<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    customerId?: string | null;
    /**
     * Search using labels in the format key:v1:v2:v3;key2:v1
     * Each part delimited by `;` will be applied with `or` between them, the same goes for  the individual `v1`, `v2` etc.
     * So for example, the following query: `country:DK;imei:125989457845875 will become
     * "All SIM Cards with labels country=DK or imei=125989457845875"
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    labels?: string | null;
    /**
     * 
     * @type {ConsumptionState}
     * @memberof SimCardActionRequestFilter
     */
    consumptionState?: ConsumptionState;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    networkCredentialTypeId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    plmnSearch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionRequestFilter
     */
    usagePackageTypeId?: string | null;
    /**
     * 
     * @type {SimCardCapabilityName}
     * @memberof SimCardActionRequestFilter
     */
    simCapability?: SimCardCapabilityName;
    /**
     * 
     * @type {boolean}
     * @memberof SimCardActionRequestFilter
     */
    isMultiImsi?: boolean | null;
}

/**
 * Check if a given object implements the SimCardActionRequestFilter interface.
 */
export function instanceOfSimCardActionRequestFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardActionRequestFilterFromJSON(json: any): SimCardActionRequestFilter {
    return SimCardActionRequestFilterFromJSONTyped(json, false);
}

export function SimCardActionRequestFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardActionRequestFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'iccLowerBound': !exists(json, 'iccLowerBound') ? undefined : json['iccLowerBound'],
        'iccUpperBound': !exists(json, 'iccUpperBound') ? undefined : json['iccUpperBound'],
        'simConfigurationId': !exists(json, 'simConfigurationId') ? undefined : json['simConfigurationId'],
        'billingGroupIdSet': !exists(json, 'billingGroupIdSet') ? undefined : json['billingGroupIdSet'],
        'search': !exists(json, 'search') ? undefined : json['search'],
        'msisdnSearch': !exists(json, 'msisdnSearch') ? undefined : json['msisdnSearch'],
        'networkState': !exists(json, 'networkState') ? undefined : NetworkStateFromJSON(json['networkState']),
        'iccSet': !exists(json, 'iccSet') ? undefined : json['iccSet'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'labels': !exists(json, 'labels') ? undefined : json['labels'],
        'consumptionState': !exists(json, 'consumptionState') ? undefined : ConsumptionStateFromJSON(json['consumptionState']),
        'networkCredentialTypeId': !exists(json, 'networkCredentialTypeId') ? undefined : json['networkCredentialTypeId'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'plmnSearch': !exists(json, 'plmnSearch') ? undefined : json['plmnSearch'],
        'usagePackageTypeId': !exists(json, 'usagePackageTypeId') ? undefined : json['usagePackageTypeId'],
        'simCapability': !exists(json, 'simCapability') ? undefined : SimCardCapabilityNameFromJSON(json['simCapability']),
        'isMultiImsi': !exists(json, 'isMultiImsi') ? undefined : json['isMultiImsi'],
    };
}

export function SimCardActionRequestFilterToJSON(value?: SimCardActionRequestFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'iccLowerBound': value.iccLowerBound,
        'iccUpperBound': value.iccUpperBound,
        'simConfigurationId': value.simConfigurationId,
        'billingGroupIdSet': value.billingGroupIdSet === undefined ? undefined : value.billingGroupIdSet === null ? null : Array.from(value.billingGroupIdSet as Set<any>),
        'search': value.search,
        'msisdnSearch': value.msisdnSearch,
        'networkState': NetworkStateToJSON(value.networkState),
        'iccSet': value.iccSet === undefined ? undefined : value.iccSet === null ? null : Array.from(value.iccSet as Set<any>),
        'customerId': value.customerId,
        'labels': value.labels,
        'consumptionState': ConsumptionStateToJSON(value.consumptionState),
        'networkCredentialTypeId': value.networkCredentialTypeId,
        'country': value.country,
        'plmnSearch': value.plmnSearch,
        'usagePackageTypeId': value.usagePackageTypeId,
        'simCapability': SimCardCapabilityNameToJSON(value.simCapability),
        'isMultiImsi': value.isMultiImsi,
    };
}

