import React, { useMemo } from "react";
import { DownloadIcon, SearchBar, SearchSingleInput, singleInput, useSearch } from "@cobira/ui-library";
import { CostPlan, TelcoCost } from "../../api";
import { Button, HStack, Icon, VStack } from "@chakra-ui/react";
import { DEFAULT_COST_CURRENCY } from "../../utils/pricingUtils";
import { TelcoCostCsvConfig } from "../../utils/csv/telcoCostCsvConfig";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import { stringify } from "csv-stringify/browser/esm";
import TelcoCostTable from "./TelcoCostTable";

export interface TelcoCostTableWithSearchProps {
    costPlan?: CostPlan;
    telcoCosts: TelcoCost[];
    isLoading?: boolean;
}

const TelcoCostTableWithSearch = ({ telcoCosts, isLoading, costPlan }: TelcoCostTableWithSearchProps) => {
    const search = useSearch({
        config: {
            plmn: singleInput("PLMN"),
        },
    });

    const filteredTelcoCosts = useMemo(() => {
        const plmnSearch = search.state?.plmn?.value;
        return telcoCosts.filter(cost => (!!plmnSearch && cost.plmn?.includes(plmnSearch)) || !plmnSearch);
    }, [telcoCosts, search.state]);

    const { ref, url, download, fileName } = useDownloadFile({
        downloadFunction: () => {
            return new Promise<Blob>((resolve, reject) => {
                stringify(
                    (filteredTelcoCosts || []).map(cost => ({
                        ...cost,
                        byteCost: (cost.byteCost || 0) * 1000.0 * 1000.0,
                        currency: DEFAULT_COST_CURRENCY,
                    })),
                    {
                        header: true,
                        delimiter: TelcoCostCsvConfig.delimiter,
                        columns: TelcoCostCsvConfig.columnConfig,
                    },
                    (err, output) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(new Blob([output], { type: "text/csv" }));
                        }
                    },
                );
            });
        },
        fileName: `${costPlan?.name || "cost_plan"}_costs.csv`,
    });

    return (
        <VStack w={"100%"} gap={2}>
            <a href={url} download={fileName} hidden ref={ref} />
            <HStack w={"100%"}>
                <SearchBar
                    useSearch={search}
                    defaultFilterInputId={"plmn"}
                    filterInputs={[
                        {
                            id: "plmn",
                            menuLabel: "PLMN",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "plmn" })}
                                    placeholder={"Search by PLMN"}
                                />
                            ),
                        },
                    ]}
                />
                <Button mb={"auto"} leftIcon={<Icon as={DownloadIcon} />} onClick={download}>
                    Download
                </Button>
            </HStack>
            <TelcoCostTable telcoCosts={filteredTelcoCosts} isLoading={isLoading} />
        </VStack>
    );
};

export default TelcoCostTableWithSearch;
