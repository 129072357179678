import React from "react";
import { CobiraTabs, PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import CostPlansPanel from "./CostPlansPanel/CostPlansPanel";
import NetworkCredentialTypeDetailPanel from "./NetworkCredentialTypeDetailPanel/NetworkCredentialTypeDetailPanel";
import { tab } from "../../../utils/tabUtils";

const NetworkCredentialTypeDetailPage = () => {
    const { configurationApi } = useApi();
    const { networkCredentialTypeId } = useParams();

    const networkCredentialTypeQuery = useQuery(
        ["networkcredentialtypes", networkCredentialTypeId],
        () => configurationApi.getNetworkCredentialType({ networkCredentialTypeId: networkCredentialTypeId || "" }),
        {
            enabled: !!networkCredentialTypeId,
        },
    );

    const networkCredentialTypeDetailTabs = [
        tab("Details", <NetworkCredentialTypeDetailPanel query={networkCredentialTypeQuery} />),
        tab("Cost Plans", <CostPlansPanel query={networkCredentialTypeQuery} />),
    ];

    return (
        <PageLayout
            title={<PageTitle title={networkCredentialTypeQuery?.data?.imsiProvider || "Network Credential Type"} />}
            navigation={<PageBackButton text={"Back to network credential types"} path={"/configuration?tab=1"} />}
        >
            <CobiraTabs tabs={networkCredentialTypeDetailTabs} />
        </PageLayout>
    );
};

export default NetworkCredentialTypeDetailPage;
