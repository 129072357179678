import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { CostPlan } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import CostPlanDetails from "../../../../components/CostPlanDetails/CostPlanDetails";

export interface CostPlanDetailsPanelProps {
    query: UseQueryResult<CostPlan>;
}

const CostPlanDetailsPanel = ({ query: { data: costPlan, isLoading } }: CostPlanDetailsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <CostPlanDetails costPlan={costPlan} isLoading={isLoading} />
        </TabPanel>
    );
};

export default CostPlanDetailsPanel;
