/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Action,
        AssignNetworkCredentialType,
        AssignSimConfiguration,
        ConsumptionState,
        CostPlan,
        CostPlanPage,
        CreateCostPlan,
        CreateTenant,
        CreateUsagePackageType,
        NetworkCredentialType,
        NetworkCredentialTypePage,
        SimCardCapabilityName,
        SimConfiguration,
        SimConfigurationPage,
        TelcoCost,
        TelcoCostPage,
        Tenant,
        TenantPage,
        UpdateCostPlan,
        UpdateTenant,
        UsagePackageType,
        UsagePackageTypePage,
        YearMonth,
    } from '../models';
        import {
            ActionFromJSON,
            ActionToJSON,
            AssignNetworkCredentialTypeFromJSON,
            AssignNetworkCredentialTypeToJSON,
            AssignSimConfigurationFromJSON,
            AssignSimConfigurationToJSON,
            ConsumptionStateFromJSON,
            ConsumptionStateToJSON,
            CostPlanFromJSON,
            CostPlanToJSON,
            CostPlanPageFromJSON,
            CostPlanPageToJSON,
            CreateCostPlanFromJSON,
            CreateCostPlanToJSON,
            CreateTenantFromJSON,
            CreateTenantToJSON,
            CreateUsagePackageTypeFromJSON,
            CreateUsagePackageTypeToJSON,
            NetworkCredentialTypeFromJSON,
            NetworkCredentialTypeToJSON,
            NetworkCredentialTypePageFromJSON,
            NetworkCredentialTypePageToJSON,
            SimCardCapabilityNameFromJSON,
            SimCardCapabilityNameToJSON,
            SimConfigurationFromJSON,
            SimConfigurationToJSON,
            SimConfigurationPageFromJSON,
            SimConfigurationPageToJSON,
            TelcoCostFromJSON,
            TelcoCostToJSON,
            TelcoCostPageFromJSON,
            TelcoCostPageToJSON,
            TenantFromJSON,
            TenantToJSON,
            TenantPageFromJSON,
            TenantPageToJSON,
            UpdateCostPlanFromJSON,
            UpdateCostPlanToJSON,
            UpdateTenantFromJSON,
            UpdateTenantToJSON,
            UsagePackageTypeFromJSON,
            UsagePackageTypeToJSON,
            UsagePackageTypePageFromJSON,
            UsagePackageTypePageToJSON,
            YearMonthFromJSON,
            YearMonthToJSON,
        } from '../models';

            export interface TenantApiCreateTenantRequest {
                createTenant: CreateTenant;
            }

            export interface TenantApiCreateTenantNetworkCredentialTypeRequest {
                tenantId: string;
                assignNetworkCredentialType: AssignNetworkCredentialType;
            }

            export interface TenantApiCreateTenantNetworkCredentialTypeCostPlanRequest {
                tenantId: string;
                networkCredentialTypeId: string;
                createCostPlan: CreateCostPlan;
            }

            export interface TenantApiCreateTenantSimConfigurationRequest {
                tenantId: string;
                assignSimConfiguration: AssignSimConfiguration;
            }

            export interface TenantApiCreateTenantUsagePackageTypeRequest {
                tenantId: string;
                createUsagePackageType: CreateUsagePackageType;
            }

            export interface TenantApiDeleteTenantNetworkCredentialTypeRequest {
                tenantId: string;
                networkCredentialTypeId: string;
            }

            export interface TenantApiDeleteTenantSimConfigurationRequest {
                tenantId: string;
                simConfigurationId: string;
            }

            export interface TenantApiDeleteTenantUsagePackageTypeRequest {
                tenantId: string;
                usagePackageTypeId: string;
            }

            export interface TenantApiGetTenantRequest {
                tenantId: string;
            }

            export interface TenantApiGetTenantNetworkCredentialTypeRequest {
                tenantId: string;
                networkCredentialTypeId: string;
            }

            export interface TenantApiGetTenantNetworkCredentialTypeCostPlanRequest {
                tenantId: string;
                networkCredentialTypeId: string;
                costPlanId: string;
            }

            export interface TenantApiGetTenantNetworkCredentialTypeCostPlanTelcoCostsRequest {
                tenantId: string;
                networkCredentialTypeId: string;
                costPlanId: string;
                pageSize: number;
                pageNumber: number;
            }

            export interface TenantApiGetTenantNetworkCredentialTypeCostPlansRequest {
                tenantId: string;
                networkCredentialTypeId: string;
                pageSize: number;
                pageNumber: number;
                yearMonth?: YearMonth;
            }

            export interface TenantApiGetTenantNetworkCredentialTypesRequest {
                tenantId: string;
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
            }

            export interface TenantApiGetTenantSimConfigurationRequest {
                tenantId: string;
                simConfigurationId: string;
            }

            export interface TenantApiGetTenantSimConfigurationsRequest {
                tenantId: string;
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
                search?: string;
                simCapability?: SimCardCapabilityName;
                networkCredentialTypeSearch?: string;
            }

            export interface TenantApiGetTenantUsagePackageTypeRequest {
                tenantId: string;
                usagePackageTypeId: string;
            }

            export interface TenantApiGetTenantUsagePackageTypesRequest {
                tenantId: string;
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
                search?: string;
                country?: string;
                dataPackageEnabled?: boolean | null;
                smsPackageEnabled?: boolean | null;
                networkCredentialType?: string | null;
            }

            export interface TenantApiGetTenantsRequest {
                pageNumber: number;
                pageSize: number;
                search?: string;
                consumptionState?: ConsumptionState;
            }

            export interface TenantApiPatchTenantNetworkCredentialTypeCostPlanTelcoCostsRequest {
                tenantId: string;
                networkCredentialTypeId: string;
                costPlanId: string;
                telcoCost: Array<TelcoCost>;
            }

            export interface TenantApiSendTenantInvitationRequest {
                tenantId: string;
            }

            export interface TenantApiUpdateTenantRequest {
                tenantId: string;
                updateTenant: UpdateTenant;
            }

            export interface TenantApiUpdateTenantNetworkCredentialTypeCostPlanRequest {
                tenantId: string;
                networkCredentialTypeId: string;
                costPlanId: string;
                updateCostPlan: UpdateCostPlan;
            }

            export interface TenantApiUpdateTenantNetworkCredentialTypeCostPlanTelcoCostsRequest {
                tenantId: string;
                networkCredentialTypeId: string;
                costPlanId: string;
                telcoCost: Array<TelcoCost>;
            }

        /**
        * 
        */
            export class TenantApi extends runtime.BaseAPI {

            /**
                * Creating a new tenant will give the created tenant access to the MVNO services. The admin information specified during creation, can be used to invite the admin user to the MVNO. To send the invitation please use the `/tenants/{tenantId}/sendinvite` operation to send it. 
                * Create a new Tenant
            */
            async createTenantRaw(requestParameters: TenantApiCreateTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tenant>> {
                    if (requestParameters.createTenant === null || requestParameters.createTenant === undefined) {
                    throw new runtime.RequiredError('createTenant','Required parameter requestParameters.createTenant was null or undefined when calling createTenant.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateTenantToJSON(requestParameters.createTenant),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
            }

            /**
                * Creating a new tenant will give the created tenant access to the MVNO services. The admin information specified during creation, can be used to invite the admin user to the MVNO. To send the invitation please use the `/tenants/{tenantId}/sendinvite` operation to send it. 
                * Create a new Tenant
            */
                async createTenant(requestParameters: TenantApiCreateTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tenant> {
                    const response = await this.createTenantRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Makes the network credential type available to the child tenant. This will enable assignment of SIM Configurations that utilize this network credential type. 
                * Make the network credential type available to the child tenant.
            */
            async createTenantNetworkCredentialTypeRaw(requestParameters: TenantApiCreateTenantNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createTenantNetworkCredentialType.');
                    }

                    if (requestParameters.assignNetworkCredentialType === null || requestParameters.assignNetworkCredentialType === undefined) {
                    throw new runtime.RequiredError('assignNetworkCredentialType','Required parameter requestParameters.assignNetworkCredentialType was null or undefined when calling createTenantNetworkCredentialType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: AssignNetworkCredentialTypeToJSON(requestParameters.assignNetworkCredentialType),
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Makes the network credential type available to the child tenant. This will enable assignment of SIM Configurations that utilize this network credential type. 
                * Make the network credential type available to the child tenant.
            */
                async createTenantNetworkCredentialType(requestParameters: TenantApiCreateTenantNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.createTenantNetworkCredentialTypeRaw(requestParameters, initOverrides);
                }

            /**
                * Creates a new cost plan for the given Network Credential Type. The new cost plan must not clash with an existing cost plan via its `validAt` property. 
                * Create New Cost Plan
            */
            async createTenantNetworkCredentialTypeCostPlanRaw(requestParameters: TenantApiCreateTenantNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlan>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createTenantNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling createTenantNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.createCostPlan === null || requestParameters.createCostPlan === undefined) {
                    throw new runtime.RequiredError('createCostPlan','Required parameter requestParameters.createCostPlan was null or undefined when calling createTenantNetworkCredentialTypeCostPlan.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}/costplans`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateCostPlanToJSON(requestParameters.createCostPlan),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanFromJSON(jsonValue));
            }

            /**
                * Creates a new cost plan for the given Network Credential Type. The new cost plan must not clash with an existing cost plan via its `validAt` property. 
                * Create New Cost Plan
            */
                async createTenantNetworkCredentialTypeCostPlan(requestParameters: TenantApiCreateTenantNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlan> {
                    const response = await this.createTenantNetworkCredentialTypeCostPlanRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Makes the sim configuration available to the child tenant. The assignment requires that all the network credential types that the sim configuration supports are assigned to the child tenant prior to this operation. Once assigned, SIM Cards that are configured with the assigned SIM Configuration will be available for assignment to the child tenant. 
                * Make the sim configurations available to the child tenant.
            */
            async createTenantSimConfigurationRaw(requestParameters: TenantApiCreateTenantSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createTenantSimConfiguration.');
                    }

                    if (requestParameters.assignSimConfiguration === null || requestParameters.assignSimConfiguration === undefined) {
                    throw new runtime.RequiredError('assignSimConfiguration','Required parameter requestParameters.assignSimConfiguration was null or undefined when calling createTenantSimConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/simconfigurations`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: AssignSimConfigurationToJSON(requestParameters.assignSimConfiguration),
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Makes the sim configuration available to the child tenant. The assignment requires that all the network credential types that the sim configuration supports are assigned to the child tenant prior to this operation. Once assigned, SIM Cards that are configured with the assigned SIM Configuration will be available for assignment to the child tenant. 
                * Make the sim configurations available to the child tenant.
            */
                async createTenantSimConfiguration(requestParameters: TenantApiCreateTenantSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.createTenantSimConfigurationRaw(requestParameters, initOverrides);
                }

            /**
                * Creates a new Usage Package Type available to the child tenant. 
                * Make Usage Package Type available to the child tenant.
            */
            async createTenantUsagePackageTypeRaw(requestParameters: TenantApiCreateTenantUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling createTenantUsagePackageType.');
                    }

                    if (requestParameters.createUsagePackageType === null || requestParameters.createUsagePackageType === undefined) {
                    throw new runtime.RequiredError('createUsagePackageType','Required parameter requestParameters.createUsagePackageType was null or undefined when calling createTenantUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/usagepackagetypes`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateUsagePackageTypeToJSON(requestParameters.createUsagePackageType),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Creates a new Usage Package Type available to the child tenant. 
                * Make Usage Package Type available to the child tenant.
            */
                async createTenantUsagePackageType(requestParameters: TenantApiCreateTenantUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.createTenantUsagePackageTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Marks the network credential type as being revoked, this will ensure that the child tenant can no longer use that network credential type for future SIM Card assignments. Existing SIM Cards that use that network credential type, will continue to work and rely on the cost plan associated with the network credential type. 
                * Remove a network credential type from the child tenant
            */
            async deleteTenantNetworkCredentialTypeRaw(requestParameters: TenantApiDeleteTenantNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteTenantNetworkCredentialType.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling deleteTenantNetworkCredentialType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Marks the network credential type as being revoked, this will ensure that the child tenant can no longer use that network credential type for future SIM Card assignments. Existing SIM Cards that use that network credential type, will continue to work and rely on the cost plan associated with the network credential type. 
                * Remove a network credential type from the child tenant
            */
                async deleteTenantNetworkCredentialType(requestParameters: TenantApiDeleteTenantNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteTenantNetworkCredentialTypeRaw(requestParameters, initOverrides);
                }

            /**
                * Marks the sim configuration as being revoked, this will ensure that the child tenant can no longer use that sim configuration for future SIM Card assignments. Existing SIM Cards that use that sim configuration, will continue to function as usual. 
                * Remove a sim configuration from the child tenant
            */
            async deleteTenantSimConfigurationRaw(requestParameters: TenantApiDeleteTenantSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteTenantSimConfiguration.');
                    }

                    if (requestParameters.simConfigurationId === null || requestParameters.simConfigurationId === undefined) {
                    throw new runtime.RequiredError('simConfigurationId','Required parameter requestParameters.simConfigurationId was null or undefined when calling deleteTenantSimConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/simconfigurations/{simConfigurationId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"simConfigurationId"}}`, encodeURIComponent(String(requestParameters.simConfigurationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Marks the sim configuration as being revoked, this will ensure that the child tenant can no longer use that sim configuration for future SIM Card assignments. Existing SIM Cards that use that sim configuration, will continue to function as usual. 
                * Remove a sim configuration from the child tenant
            */
                async deleteTenantSimConfiguration(requestParameters: TenantApiDeleteTenantSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteTenantSimConfigurationRaw(requestParameters, initOverrides);
                }

            /**
                * Marks a usage package type for soft deletion. Once deleted the child tenant can no longer use the usage package type for assignment to new SIM cards. The assigned usage packages will continue to function, unless revoked from the assigned SIM cards. 
                * Delete usage package type from child tenant
            */
            async deleteTenantUsagePackageTypeRaw(requestParameters: TenantApiDeleteTenantUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling deleteTenantUsagePackageType.');
                    }

                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling deleteTenantUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Marks a usage package type for soft deletion. Once deleted the child tenant can no longer use the usage package type for assignment to new SIM cards. The assigned usage packages will continue to function, unless revoked from the assigned SIM cards. 
                * Delete usage package type from child tenant
            */
                async deleteTenantUsagePackageType(requestParameters: TenantApiDeleteTenantUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteTenantUsagePackageTypeRaw(requestParameters, initOverrides);
                }

            /**
                * Get a single tenant by its id.
                * Get Tenant
            */
            async getTenantRaw(requestParameters: TenantApiGetTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tenant>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenant.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
            }

            /**
                * Get a single tenant by its id.
                * Get Tenant
            */
                async getTenant(requestParameters: TenantApiGetTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tenant> {
                    const response = await this.getTenantRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get network credential type
            */
            async getTenantNetworkCredentialTypeRaw(requestParameters: TenantApiGetTenantNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialType>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantNetworkCredentialType.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getTenantNetworkCredentialType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialTypeFromJSON(jsonValue));
            }

            /**
                * Get network credential type
            */
                async getTenantNetworkCredentialType(requestParameters: TenantApiGetTenantNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialType> {
                    const response = await this.getTenantNetworkCredentialTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches the information for the cost plan
                * Get Network Credential Type Cost Plan
            */
            async getTenantNetworkCredentialTypeCostPlanRaw(requestParameters: TenantApiGetTenantNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlan>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getTenantNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getTenantNetworkCredentialTypeCostPlan.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanFromJSON(jsonValue));
            }

            /**
                * Fetches the information for the cost plan
                * Get Network Credential Type Cost Plan
            */
                async getTenantNetworkCredentialTypeCostPlan(requestParameters: TenantApiGetTenantNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlan> {
                    const response = await this.getTenantNetworkCredentialTypeCostPlanRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches telco costs for the cost plan.
                * Get Telco Costs
            */
            async getTenantNetworkCredentialTypeCostPlanTelcoCostsRaw(requestParameters: TenantApiGetTenantNetworkCredentialTypeCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TelcoCostPage>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}/telcocosts`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => TelcoCostPageFromJSON(jsonValue));
            }

            /**
                * Fetches telco costs for the cost plan.
                * Get Telco Costs
            */
                async getTenantNetworkCredentialTypeCostPlanTelcoCosts(requestParameters: TenantApiGetTenantNetworkCredentialTypeCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TelcoCostPage> {
                    const response = await this.getTenantNetworkCredentialTypeCostPlanTelcoCostsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches cost plans for a given network credential type. During rating, the active cost plan is selected based on the `validAt` property
                * Get the network credential type cost plans for the tenant
            */
            async getTenantNetworkCredentialTypeCostPlansRaw(requestParameters: TenantApiGetTenantNetworkCredentialTypeCostPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlanPage>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantNetworkCredentialTypeCostPlans.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getTenantNetworkCredentialTypeCostPlans.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTenantNetworkCredentialTypeCostPlans.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTenantNetworkCredentialTypeCostPlans.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.yearMonth !== undefined) {
                            queryParameters['yearMonth'] = requestParameters.yearMonth;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}/costplans`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanPageFromJSON(jsonValue));
            }

            /**
                * Fetches cost plans for a given network credential type. During rating, the active cost plan is selected based on the `validAt` property
                * Get the network credential type cost plans for the tenant
            */
                async getTenantNetworkCredentialTypeCostPlans(requestParameters: TenantApiGetTenantNetworkCredentialTypeCostPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlanPage> {
                    const response = await this.getTenantNetworkCredentialTypeCostPlansRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all network credential types available to the child tenant
                * Get network credential types available to the child tenant
            */
            async getTenantNetworkCredentialTypesRaw(requestParameters: TenantApiGetTenantNetworkCredentialTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialTypePage>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantNetworkCredentialTypes.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTenantNetworkCredentialTypes.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTenantNetworkCredentialTypes.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialTypePageFromJSON(jsonValue));
            }

            /**
                * Get all network credential types available to the child tenant
                * Get network credential types available to the child tenant
            */
                async getTenantNetworkCredentialTypes(requestParameters: TenantApiGetTenantNetworkCredentialTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialTypePage> {
                    const response = await this.getTenantNetworkCredentialTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get SIM Configuration
            */
            async getTenantSimConfigurationRaw(requestParameters: TenantApiGetTenantSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfiguration>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantSimConfiguration.');
                    }

                    if (requestParameters.simConfigurationId === null || requestParameters.simConfigurationId === undefined) {
                    throw new runtime.RequiredError('simConfigurationId','Required parameter requestParameters.simConfigurationId was null or undefined when calling getTenantSimConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/simconfigurations/{simConfigurationId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"simConfigurationId"}}`, encodeURIComponent(String(requestParameters.simConfigurationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationFromJSON(jsonValue));
            }

            /**
                * Get SIM Configuration
            */
                async getTenantSimConfiguration(requestParameters: TenantApiGetTenantSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfiguration> {
                    const response = await this.getTenantSimConfigurationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all sim configurations available to the child tenant
                * Get SIM Configurations assigned to the child tenant
            */
            async getTenantSimConfigurationsRaw(requestParameters: TenantApiGetTenantSimConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfigurationPage>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantSimConfigurations.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTenantSimConfigurations.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTenantSimConfigurations.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.simCapability !== undefined) {
                            queryParameters['simCapability'] = requestParameters.simCapability;
                    }

                    if (requestParameters.networkCredentialTypeSearch !== undefined) {
                            queryParameters['networkCredentialTypeSearch'] = requestParameters.networkCredentialTypeSearch;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/simconfigurations`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationPageFromJSON(jsonValue));
            }

            /**
                * Get all sim configurations available to the child tenant
                * Get SIM Configurations assigned to the child tenant
            */
                async getTenantSimConfigurations(requestParameters: TenantApiGetTenantSimConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfigurationPage> {
                    const response = await this.getTenantSimConfigurationsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch the information for a single usage package type from the child tenant.
                * Get a child tenant usage package type
            */
            async getTenantUsagePackageTypeRaw(requestParameters: TenantApiGetTenantUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageType>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantUsagePackageType.');
                    }

                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling getTenantUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypeFromJSON(jsonValue));
            }

            /**
                * Fetch the information for a single usage package type from the child tenant.
                * Get a child tenant usage package type
            */
                async getTenantUsagePackageType(requestParameters: TenantApiGetTenantUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageType> {
                    const response = await this.getTenantUsagePackageTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all usage package types available to the child tenant
                * Get usage package types available to the child tenant
            */
            async getTenantUsagePackageTypesRaw(requestParameters: TenantApiGetTenantUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageTypePage>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantUsagePackageTypes.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTenantUsagePackageTypes.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTenantUsagePackageTypes.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.country !== undefined) {
                            queryParameters['country'] = requestParameters.country;
                    }

                    if (requestParameters.dataPackageEnabled !== undefined) {
                            queryParameters['dataPackageEnabled'] = requestParameters.dataPackageEnabled;
                    }

                    if (requestParameters.smsPackageEnabled !== undefined) {
                            queryParameters['smsPackageEnabled'] = requestParameters.smsPackageEnabled;
                    }

                    if (requestParameters.networkCredentialType !== undefined) {
                            queryParameters['networkCredentialType'] = requestParameters.networkCredentialType;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/usagepackagetypes`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypePageFromJSON(jsonValue));
            }

            /**
                * Get all usage package types available to the child tenant
                * Get usage package types available to the child tenant
            */
                async getTenantUsagePackageTypes(requestParameters: TenantApiGetTenantUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageTypePage> {
                    const response = await this.getTenantUsagePackageTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all tenants that are created under your current tenant.
                * Get Tenants
            */
            async getTenantsRaw(requestParameters: TenantApiGetTenantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TenantPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getTenants.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getTenants.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.consumptionState !== undefined) {
                            queryParameters['consumptionState'] = requestParameters.consumptionState;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => TenantPageFromJSON(jsonValue));
            }

            /**
                * Get all tenants that are created under your current tenant.
                * Get Tenants
            */
                async getTenants(requestParameters: TenantApiGetTenantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TenantPage> {
                    const response = await this.getTenantsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Update or create costs from the provided payload. If an existing cost is found, it will be updated, otherwise it will be added to the cost plan. 
                * Update Telco Costs
            */
            async patchTenantNetworkCredentialTypeCostPlanTelcoCostsRaw(requestParameters: TenantApiPatchTenantNetworkCredentialTypeCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling patchTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling patchTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling patchTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.telcoCost === null || requestParameters.telcoCost === undefined) {
                    throw new runtime.RequiredError('telcoCost','Required parameter requestParameters.telcoCost was null or undefined when calling patchTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}/telcocosts`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                            body: requestParameters.telcoCost.map(TelcoCostToJSON),
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Update or create costs from the provided payload. If an existing cost is found, it will be updated, otherwise it will be added to the cost plan. 
                * Update Telco Costs
            */
                async patchTenantNetworkCredentialTypeCostPlanTelcoCosts(requestParameters: TenantApiPatchTenantNetworkCredentialTypeCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.patchTenantNetworkCredentialTypeCostPlanTelcoCostsRaw(requestParameters, initOverrides);
                }

            /**
                * Sends an invitation to the tenant admin, if the admin does not accept the invitation within the time period, a new invitation can be sent using the same operation 
                * Send an invitation to the tenant admin
            */
            async sendTenantInvitationRaw(requestParameters: TenantApiSendTenantInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling sendTenantInvitation.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/sendinvite`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Sends an invitation to the tenant admin, if the admin does not accept the invitation within the time period, a new invitation can be sent using the same operation 
                * Send an invitation to the tenant admin
            */
                async sendTenantInvitation(requestParameters: TenantApiSendTenantInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.sendTenantInvitationRaw(requestParameters, initOverrides);
                }

            /**
                * The update operation can be used to change the admin of the tenant, to do this, simply provide the updated information and then use the `/tenants/{tenantId}/sendinvite` operation. Once the new admin has accepted the invitation, they can change the access of the previous admin if needed. 
                * Update information regarding a tenant.
            */
            async updateTenantRaw(requestParameters: TenantApiUpdateTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tenant>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling updateTenant.');
                    }

                    if (requestParameters.updateTenant === null || requestParameters.updateTenant === undefined) {
                    throw new runtime.RequiredError('updateTenant','Required parameter requestParameters.updateTenant was null or undefined when calling updateTenant.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateTenantToJSON(requestParameters.updateTenant),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => TenantFromJSON(jsonValue));
            }

            /**
                * The update operation can be used to change the admin of the tenant, to do this, simply provide the updated information and then use the `/tenants/{tenantId}/sendinvite` operation. Once the new admin has accepted the invitation, they can change the access of the previous admin if needed. 
                * Update information regarding a tenant.
            */
                async updateTenant(requestParameters: TenantApiUpdateTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tenant> {
                    const response = await this.updateTenantRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Updates information for a cost plan, such as name and description.
                * Update Cost Plan Info
            */
            async updateTenantNetworkCredentialTypeCostPlanRaw(requestParameters: TenantApiUpdateTenantNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlan>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling updateTenantNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling updateTenantNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling updateTenantNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.updateCostPlan === null || requestParameters.updateCostPlan === undefined) {
                    throw new runtime.RequiredError('updateCostPlan','Required parameter requestParameters.updateCostPlan was null or undefined when calling updateTenantNetworkCredentialTypeCostPlan.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateCostPlanToJSON(requestParameters.updateCostPlan),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanFromJSON(jsonValue));
            }

            /**
                * Updates information for a cost plan, such as name and description.
                * Update Cost Plan Info
            */
                async updateTenantNetworkCredentialTypeCostPlan(requestParameters: TenantApiUpdateTenantNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlan> {
                    const response = await this.updateTenantNetworkCredentialTypeCostPlanRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Replaces all of the telco costs for the specified cost plan with a new set of costs.  Beware that this will take effect from the `validAt` date. 
                * Replace Telco Costs
            */
            async updateTenantNetworkCredentialTypeCostPlanTelcoCostsRaw(requestParameters: TenantApiUpdateTenantNetworkCredentialTypeCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
                    throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling updateTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling updateTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling updateTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

                    if (requestParameters.telcoCost === null || requestParameters.telcoCost === undefined) {
                    throw new runtime.RequiredError('telcoCost','Required parameter requestParameters.telcoCost was null or undefined when calling updateTenantNetworkCredentialTypeCostPlanTelcoCosts.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/tenants/{tenantId}/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}/telcocosts`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
                            body: requestParameters.telcoCost.map(TelcoCostToJSON),
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Replaces all of the telco costs for the specified cost plan with a new set of costs.  Beware that this will take effect from the `validAt` date. 
                * Replace Telco Costs
            */
                async updateTenantNetworkCredentialTypeCostPlanTelcoCosts(requestParameters: TenantApiUpdateTenantNetworkCredentialTypeCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.updateTenantNetworkCredentialTypeCostPlanTelcoCostsRaw(requestParameters, initOverrides);
                }

        }
