import React, { useMemo } from "react";
import { z } from "zod";
import { createWizardStep, intToMonth, Month, Wizard } from "@cobira/ui-library";
import CostPlanDetailsFormStep, { CostPlanDetailsSchema } from "./CostPlanDetailsFormStep";
import CostPlanCostsFormStep, { CostPlanCostsSchema } from "./CostPlanCostsFormStep";
import ConfirmCostOverrideCostPlanStep from "./ConfirmCostOverrideCostPlanStep";
import CostOverrideTargetsCostPlanFormStep, {
    CostOverrideTargetsCostPlanSchema,
} from "./CostOverrideTargetsCostPlanFormStep";
import { CostPlan, TelcoCost } from "../../api";

const CostOverrideCostPlanSchema = CostPlanDetailsSchema.merge(CostPlanCostsSchema).merge(
    CostOverrideTargetsCostPlanSchema,
);

export type CostOverrideCostPlanSchemaType = z.infer<typeof CostOverrideCostPlanSchema>;

export interface CostOverrideCostPlanFormProps {
    onSubmit: (form: CostOverrideCostPlanSchemaType) => void;
    defaultValue?: {
        costPlan?: CostPlan;
        telcoCosts?: TelcoCost[];
    };
}

const CostOverrideCostPlanForm = ({ onSubmit, defaultValue }: CostOverrideCostPlanFormProps) => {
    const formSteps = useMemo(
        () => [
            createWizardStep(<CostPlanDetailsFormStep />, CostPlanDetailsSchema, {
                stepComponent: "Details",
                defaultValue: {
                    name: defaultValue?.costPlan?.name || "",
                    description: defaultValue?.costPlan?.description,
                    appliesFrom: {
                        year: defaultValue?.costPlan?.validAt?.year || new Date().getUTCFullYear(),
                        month:
                            (defaultValue?.costPlan?.validAt?.month as Month) || intToMonth(new Date().getUTCMonth()),
                    },
                },
            }),
            createWizardStep(<CostPlanCostsFormStep />, CostPlanCostsSchema, {
                stepComponent: "Define Costs",
                defaultValue: {
                    telcoCosts: defaultValue?.telcoCosts || [],
                    activeImsiCost: defaultValue?.costPlan?.activeImsiCost || 0,
                },
            }),
            createWizardStep(<CostOverrideTargetsCostPlanFormStep />, CostOverrideTargetsCostPlanSchema, {
                stepComponent: "Applicable SIM Cards",
                defaultValue: {
                    iccRanges: defaultValue?.costPlan?.costPlanOverrides?.map(override => ({
                        iccFrom: override.iccRange.iccStart,
                        iccTo: override.iccRange.iccEnd,
                    })) || [
                        {
                            iccFrom: "",
                            iccTo: "",
                        },
                    ],
                },
            }),
        ],
        [defaultValue],
    );

    const confirmStep = useMemo(
        () =>
            createWizardStep(<ConfirmCostOverrideCostPlanStep />, CostOverrideCostPlanSchema, {
                stepComponent: "Confirm",
            }),
        [],
    );

    return <Wizard steps={formSteps} confirmStep={confirmStep} onSubmit={onSubmit} />;
};

export default CostOverrideCostPlanForm;
