import React from "react";
import { CobiraTabs, PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import CostPlanDetailsPanel from "./CostPlanDetailsPanel/CostPlanDetailsPanel";
import TelcoCostsPanel from "./TelcoCostsPanel/TelcoCostsPanel";
import { tab } from "../../../utils/tabUtils";

const CostPlanDetailPage = () => {
    const { configurationApi } = useApi();
    const { networkCredentialTypeId, costPlanId } = useParams();

    const networkCredentialTypeQuery = useQuery(
        ["networkcredentialtypes", networkCredentialTypeId],
        () => configurationApi.getNetworkCredentialType({ networkCredentialTypeId: networkCredentialTypeId || "" }),
        {
            enabled: !!networkCredentialTypeId,
        },
    );

    const costPlanQuery = useQuery(
        ["networkcredentialtypes", networkCredentialTypeId, "costplans", costPlanId],
        () =>
            configurationApi.getNetworkCredentialTypeCostPlan({
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!costPlanId,
        },
    );

    const costPlanDetailTabs = [
        tab("Details", <CostPlanDetailsPanel query={costPlanQuery} />),
        tab(
            "Costs",
            <TelcoCostsPanel costPlanQuery={costPlanQuery} networkCredentialTypeQuery={networkCredentialTypeQuery} />,
        ),
    ];

    return (
        <PageLayout
            title={<PageTitle title={costPlanQuery?.data?.name || "Cost Plan"} />}
            navigation={
                <PageBackButton
                    text={"Back to network credential type"}
                    path={`/configuration/networkcredentialtypes/${networkCredentialTypeId}`}
                />
            }
        >
            <CobiraTabs tabs={costPlanDetailTabs} />
        </PageLayout>
    );
};

export default CostPlanDetailPage;
