import { RecurringFeeContext } from "../api";

export const RecurringFeeContextLabels = {
    [RecurringFeeContext.None]: "Flat Fee, without any dynamic context",
    [RecurringFeeContext.ActiveImsi]: "Unique Active IMSIs",
    [RecurringFeeContext.ActiveSim]: "Unique Active SIMs",
    [RecurringFeeContext.InventorySim]: "Assigned SIMs",
    [RecurringFeeContext.BytesUsed]: "Data Usage",
    [RecurringFeeContext.CumulativeActiveSimSet]: "Cumulative Active SIMs",
    [RecurringFeeContext.ImsiSwitches]: "IMSI Switches",
};
