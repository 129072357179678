import React from "react";
import { useFormContext } from "react-hook-form";
import { CostOverrideCostPlanSchemaType } from "./CostOverrideCostPlanForm";
import { Button, Divider, GridItem, HStack, Icon, List, ListItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { DateTime, FaIcon, Money, PlaceholderText, SimCardIcon, TextColumn, yearMonthToDate } from "@cobira/ui-library";
import { DEFAULT_COST_CURRENCY } from "../../utils/pricingUtils";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import TelcoCostTable from "../../components/TelcoCostTableWithSearch/TelcoCostTable";

const ConfirmCostOverrideCostPlanStep = () => {
    const {
        getValues,
        formState: { isSubmitting },
    } = useFormContext<CostOverrideCostPlanSchemaType>();
    const formValues = getValues();
    return (
        <VStack gap={4} mt={4}>
            <SimpleGrid columns={3} w={"100%"}>
                <GridItem>
                    <VStack gap={4} alignItems={"start"}>
                        <TextColumn heading={"Name"}>
                            <Text>{formValues.name}</Text>
                        </TextColumn>
                        <TextColumn heading={"Description"}>
                            <PlaceholderText text={formValues.description} />
                        </TextColumn>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack gap={4} alignItems={"start"}>
                        <TextColumn heading={"Applies From"}>
                            <DateTime value={yearMonthToDate(formValues.appliesFrom)} format={"MMMM, yyyy"} />
                        </TextColumn>
                        <TextColumn heading={"Active IMSI Cost"}>
                            <Money value={formValues.activeImsiCost} currency={DEFAULT_COST_CURRENCY} decimals={4} />
                        </TextColumn>
                    </VStack>
                </GridItem>
                <GridItem>
                    <TextColumn heading={"Selected ICC Ranges"}>
                        <List>
                            {formValues.iccRanges.map(iccRange => (
                                <ListItem key={iccRange.iccFrom}>
                                    <HStack w={"100%"} gap={1} alignItems={"center"}>
                                        <Icon as={SimCardIcon} h={"100%"} />
                                        <Text>{iccRange.iccFrom}</Text>
                                        <FaIcon icon={faArrowRight} h={"100%"} />
                                        <Text>{iccRange.iccTo}</Text>
                                    </HStack>
                                </ListItem>
                            ))}
                        </List>
                    </TextColumn>
                </GridItem>
            </SimpleGrid>
            <Divider />
            <TelcoCostTable telcoCosts={formValues.telcoCosts} />
            <Divider />
            <Button isLoading={isSubmitting} isDisabled={isSubmitting} type="submit" alignSelf={"start"}>
                Submit Cost Plan
            </Button>
        </VStack>
    );
};

export default ConfirmCostOverrideCostPlanStep;
