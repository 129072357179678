import React from "react";
import {
    ChevronDownIcon,
    CobiraTabs,
    PageBackButton,
    PageLayout,
    PageTitle,
    usePageNavigation,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import TenantNetworkCredentialTypeCostPlansPanel from "./CostPlansPanel/TenantNetworkCredentialTypeCostPlansPanel";
import TenantNetworkCredentialTypeDetailPanel from "./NetworkCredentialTypeDetailPanel/TenantNetworkCredentialTypeDetailPanel";
import { tab } from "../../../utils/tabUtils";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";

const TenantNetworkCredentialTypeDetailPage = () => {
    const { tenantApi } = useApi();
    const { tenantId, networkCredentialTypeId } = useParams();
    const pageNavigation = usePageNavigation<"base-create" | "override-create">({
        route: value => `/tenants/${tenantId}/networkcredentialtypes/${networkCredentialTypeId}/costplans/${value}`,
    });

    const networkCredentialTypeQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId],
        () =>
            tenantApi.getTenantNetworkCredentialType({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!tenantId,
        },
    );

    const tenantNetworkCredentialTypeDetailTabs = [
        tab(
            "Details",
            <TenantNetworkCredentialTypeDetailPanel query={networkCredentialTypeQuery} tenantId={tenantId} />,
        ),
        tab(
            "Cost Plans",
            <TenantNetworkCredentialTypeCostPlansPanel query={networkCredentialTypeQuery} tenantId={tenantId} />,
        ),
    ];

    return (
        <PageLayout
            title={<PageTitle title={networkCredentialTypeQuery?.data?.imsiProvider || "Network Credential Type"} />}
            navigation={<PageBackButton text={"Back to reseller"} path={`/tenants/${tenantId}`} />}
            pageContext={
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        Manage
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => pageNavigation.navigate("base-create")}>New Base Cost Plan</MenuItem>
                        <MenuItem onClick={() => pageNavigation.navigate("override-create")}>
                            New Cost Override
                        </MenuItem>
                    </MenuList>
                </Menu>
            }
        >
            <CobiraTabs tabs={tenantNetworkCredentialTypeDetailTabs} />
        </PageLayout>
    );
};

export default TenantNetworkCredentialTypeDetailPage;
