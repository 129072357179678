/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Contexts available for customising the rating of recurring fees.
 * Each context will resolve the corresponding number of unique entries per month to be used as a multiplier of the recurring fee price. 
 * 
 * For example if the `ACTIVE_SIM` is used, the number of SIMs with a data session or SMS sent/received will be counted towards the number of active SIM cards for that month.
 * The `NONE` context will always provide a value of `1`, so take that into account when defining the price bounds.
 * @export
 */
export const RecurringFeeContext = {
    ActiveSim: 'ACTIVE_SIM',
    ActiveImsi: 'ACTIVE_IMSI',
    InventorySim: 'INVENTORY_SIM',
    None: 'NONE',
    BytesUsed: 'BYTES_USED',
    CumulativeActiveSimSet: 'CUMULATIVE_ACTIVE_SIM_SET',
    ImsiSwitches: 'IMSI_SWITCHES'
} as const;
export type RecurringFeeContext = typeof RecurringFeeContext[keyof typeof RecurringFeeContext];


export function RecurringFeeContextFromJSON(json: any): RecurringFeeContext {
    return RecurringFeeContextFromJSONTyped(json, false);
}

export function RecurringFeeContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurringFeeContext {
    return json as RecurringFeeContext;
}

export function RecurringFeeContextToJSON(value?: RecurringFeeContext | null): any {
    return value as any;
}

