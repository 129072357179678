import React, { useCallback } from "react";
import { useParams } from "react-router";
import { PageBackButton, PageLayout, PageTitle, Paper, useNotifications } from "@cobira/ui-library";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import { CostPlan, TenantApiCreateTenantNetworkCredentialTypeCostPlanRequest } from "../../../../api";
import BaseCostPlanForm, { BaseCostPlanSchemaType } from "../../../../forms/CostPlanForm/BaseCostPlanForm";

const CreateBaseCostPlanPage = () => {
    const { tenantId, networkCredentialTypeId } = useParams();
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createCostPlan = useMutation<CostPlan, Error, TenantApiCreateTenantNetworkCredentialTypeCostPlanRequest>(
        variables => tenantApi.createTenantNetworkCredentialTypeCostPlan(variables),
        {
            onSuccess: data => {
                notifications.success(`Base Cost Plan '${data.name}' created`);
                queryClient.invalidateQueries([
                    "tenants",
                    tenantId,
                    "networkcredentialtypes",
                    networkCredentialTypeId,
                    "costplans",
                ]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: BaseCostPlanSchemaType) => {
            createCostPlan.mutate({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
                createCostPlan: {
                    name: form.name,
                    description: form.description,
                    activeImsiCost: form.activeImsiCost,
                    validAt: form.appliesFrom,
                    telcoCosts: form.telcoCosts,
                },
            });
        },
        [createCostPlan, networkCredentialTypeId, tenantId],
    );

    return (
        <PageLayout
            title={<PageTitle title={"Create Base Cost Plan"} />}
            navigation={
                <PageBackButton
                    text={"Back to network credential type"}
                    path={`tenants/${tenantId}/networkcredentialtypes/${networkCredentialTypeId}`}
                />
            }
        >
            <Paper withDivider={false} header={undefined} cardProps={{ w: "100%" }}>
                <BaseCostPlanForm onSubmit={handleSubmit} />
            </Paper>
        </PageLayout>
    );
};

export default CreateBaseCostPlanPage;
