/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        CostPlan,
        CostPlanPage,
        NetworkCredentialType,
        NetworkCredentialTypePage,
        SimCardCapabilityName,
        SimConfiguration,
        SimConfigurationPage,
        TelcoCostPage,
        YearMonth,
    } from '../models';
        import {
            CostPlanFromJSON,
            CostPlanToJSON,
            CostPlanPageFromJSON,
            CostPlanPageToJSON,
            NetworkCredentialTypeFromJSON,
            NetworkCredentialTypeToJSON,
            NetworkCredentialTypePageFromJSON,
            NetworkCredentialTypePageToJSON,
            SimCardCapabilityNameFromJSON,
            SimCardCapabilityNameToJSON,
            SimConfigurationFromJSON,
            SimConfigurationToJSON,
            SimConfigurationPageFromJSON,
            SimConfigurationPageToJSON,
            TelcoCostPageFromJSON,
            TelcoCostPageToJSON,
            YearMonthFromJSON,
            YearMonthToJSON,
        } from '../models';

            export interface ConfigurationApiGetCostPlanTelcoCostsRequest {
                networkCredentialTypeId: string;
                costPlanId: string;
                pageSize: number;
                pageNumber: number;
            }

            export interface ConfigurationApiGetNetworkCredentialTypeRequest {
                networkCredentialTypeId: string;
            }

            export interface ConfigurationApiGetNetworkCredentialTypeCostPlanRequest {
                networkCredentialTypeId: string;
                costPlanId: string;
            }

            export interface ConfigurationApiGetNetworkCredentialTypeCostPlansRequest {
                networkCredentialTypeId: string;
                pageSize: number;
                pageNumber: number;
                yearMonth?: YearMonth;
            }

            export interface ConfigurationApiGetNetworkCredentialTypesRequest {
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
            }

            export interface ConfigurationApiGetSimConfigurationRequest {
                simConfigurationId: string;
            }

            export interface ConfigurationApiGetSimConfigurationsRequest {
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
                search?: string;
                simCapability?: SimCardCapabilityName;
                networkCredentialTypeSearch?: string;
            }

        /**
        * 
        */
            export class ConfigurationApi extends runtime.BaseAPI {

            /**
                * Fetches telco costs for the cost plan.
                * Get Telco Costs
            */
            async getCostPlanTelcoCostsRaw(requestParameters: ConfigurationApiGetCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TelcoCostPage>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getCostPlanTelcoCosts.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getCostPlanTelcoCosts.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getCostPlanTelcoCosts.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getCostPlanTelcoCosts.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}/telcocosts`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => TelcoCostPageFromJSON(jsonValue));
            }

            /**
                * Fetches telco costs for the cost plan.
                * Get Telco Costs
            */
                async getCostPlanTelcoCosts(requestParameters: ConfigurationApiGetCostPlanTelcoCostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TelcoCostPage> {
                    const response = await this.getCostPlanTelcoCostsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get Network Credential type
            */
            async getNetworkCredentialTypeRaw(requestParameters: ConfigurationApiGetNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialType>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getNetworkCredentialType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialTypeFromJSON(jsonValue));
            }

            /**
                * Get Network Credential type
            */
                async getNetworkCredentialType(requestParameters: ConfigurationApiGetNetworkCredentialTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialType> {
                    const response = await this.getNetworkCredentialTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches the selected cost plan
                * Get Network Credential Type Cost Plan
            */
            async getNetworkCredentialTypeCostPlanRaw(requestParameters: ConfigurationApiGetNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlan>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getNetworkCredentialTypeCostPlan.');
                    }

                    if (requestParameters.costPlanId === null || requestParameters.costPlanId === undefined) {
                    throw new runtime.RequiredError('costPlanId','Required parameter requestParameters.costPlanId was null or undefined when calling getNetworkCredentialTypeCostPlan.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}/costplans/{costPlanId}`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))).replace(`{${"costPlanId"}}`, encodeURIComponent(String(requestParameters.costPlanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanFromJSON(jsonValue));
            }

            /**
                * Fetches the selected cost plan
                * Get Network Credential Type Cost Plan
            */
                async getNetworkCredentialTypeCostPlan(requestParameters: ConfigurationApiGetNetworkCredentialTypeCostPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlan> {
                    const response = await this.getNetworkCredentialTypeCostPlanRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetches cost plans for a given network credential type. Cost plans are chosen based on their `validAt` property.
                * Get Network Credential Type Cost Plans
            */
            async getNetworkCredentialTypeCostPlansRaw(requestParameters: ConfigurationApiGetNetworkCredentialTypeCostPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CostPlanPage>> {
                    if (requestParameters.networkCredentialTypeId === null || requestParameters.networkCredentialTypeId === undefined) {
                    throw new runtime.RequiredError('networkCredentialTypeId','Required parameter requestParameters.networkCredentialTypeId was null or undefined when calling getNetworkCredentialTypeCostPlans.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getNetworkCredentialTypeCostPlans.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getNetworkCredentialTypeCostPlans.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.yearMonth !== undefined) {
                            queryParameters['yearMonth'] = requestParameters.yearMonth;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes/{networkCredentialTypeId}/costplans`.replace(`{${"networkCredentialTypeId"}}`, encodeURIComponent(String(requestParameters.networkCredentialTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => CostPlanPageFromJSON(jsonValue));
            }

            /**
                * Fetches cost plans for a given network credential type. Cost plans are chosen based on their `validAt` property.
                * Get Network Credential Type Cost Plans
            */
                async getNetworkCredentialTypeCostPlans(requestParameters: ConfigurationApiGetNetworkCredentialTypeCostPlansRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CostPlanPage> {
                    const response = await this.getNetworkCredentialTypeCostPlansRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get Network Credential types
            */
            async getNetworkCredentialTypesRaw(requestParameters: ConfigurationApiGetNetworkCredentialTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialTypePage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getNetworkCredentialTypes.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getNetworkCredentialTypes.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentialtypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialTypePageFromJSON(jsonValue));
            }

            /**
                * Get Network Credential types
            */
                async getNetworkCredentialTypes(requestParameters: ConfigurationApiGetNetworkCredentialTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialTypePage> {
                    const response = await this.getNetworkCredentialTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single SIM configuration
                * Get SIM configuration
            */
            async getSimConfigurationRaw(requestParameters: ConfigurationApiGetSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfiguration>> {
                    if (requestParameters.simConfigurationId === null || requestParameters.simConfigurationId === undefined) {
                    throw new runtime.RequiredError('simConfigurationId','Required parameter requestParameters.simConfigurationId was null or undefined when calling getSimConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simconfigurations/{simConfigurationId}`.replace(`{${"simConfigurationId"}}`, encodeURIComponent(String(requestParameters.simConfigurationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationFromJSON(jsonValue));
            }

            /**
                * Get a single SIM configuration
                * Get SIM configuration
            */
                async getSimConfiguration(requestParameters: ConfigurationApiGetSimConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfiguration> {
                    const response = await this.getSimConfigurationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all available SIM configurations.
                * Get available SIM configurations
            */
            async getSimConfigurationsRaw(requestParameters: ConfigurationApiGetSimConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimConfigurationPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimConfigurations.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimConfigurations.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.simCapability !== undefined) {
                            queryParameters['simCapability'] = requestParameters.simCapability;
                    }

                    if (requestParameters.networkCredentialTypeSearch !== undefined) {
                            queryParameters['networkCredentialTypeSearch'] = requestParameters.networkCredentialTypeSearch;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simconfigurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimConfigurationPageFromJSON(jsonValue));
            }

            /**
                * Get all available SIM configurations.
                * Get available SIM configurations
            */
                async getSimConfigurations(requestParameters: ConfigurationApiGetSimConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimConfigurationPage> {
                    const response = await this.getSimConfigurationsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
