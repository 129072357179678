import { z } from "zod";
import React, { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AddIcon, FaIcon, FormContainer, FormField, iccString } from "@cobira/ui-library";
import { CostOverrideCostPlanSchemaType } from "./CostOverrideCostPlanForm";
import { Box, HStack, IconButton, Input, VStack } from "@chakra-ui/react";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";

export const CostOverrideTargetsCostPlanSchema = z.object({
    iccRanges: z.array(
        z.object({
            iccFrom: iccString,
            iccTo: iccString,
        }),
    ),
});

const CostOverrideTargetsCostPlanFormStep = () => {
    const {
        formState: { errors },
        register,
        control,
    } = useFormContext<CostOverrideCostPlanSchemaType>();
    const { append, remove, fields } = useFieldArray({
        control,
        name: `iccRanges`,
    });

    const appendIccRange = () => {
        append({
            iccFrom: "",
            iccTo: "",
        });
    };

    const renderedFields = useMemo(() => {
        return fields.map((_, index) => {
            return (
                <HStack w={"100%"} gap={2} key={index} alignItems={"center"}>
                    <FormField error={errors?.iccRanges?.[index]?.iccFrom} label={"ICC Start"}>
                        <Input {...register(`iccRanges.${index}.iccFrom`)} />
                    </FormField>
                    <FormField error={errors?.iccRanges?.[index]?.iccTo} label={"ICC End"}>
                        <Input {...register(`iccRanges.${index}.iccTo`)} />
                    </FormField>
                    <Box
                        h={"100%"}
                        mt={errors?.iccRanges?.[index]?.iccFrom || errors?.iccRanges?.[index]?.iccTo ? "0.4em" : "auto"}
                    >
                        <IconButton
                            isDisabled={fields.length <= 1}
                            aria-label={"Remove ICC Range"}
                            icon={<FaIcon icon={faMinus} />}
                            onClick={() => remove(index)}
                        />
                    </Box>
                </HStack>
            );
        });
    }, [errors?.iccRanges, fields, register, remove]);

    return (
        <FormContainer>
            <VStack gap={2} w={"70%"}>
                {fields && fields.length > 0 && renderedFields}
            </VStack>
            <IconButton icon={<AddIcon />} aria-label={"Add bound"} onClick={appendIccRange} />
        </FormContainer>
    );
};

export default CostOverrideTargetsCostPlanFormStep;
