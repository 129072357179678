import { z } from "zod";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FileInput, FormContainer, FormField, useNotifications } from "@cobira/ui-library";
import { Divider, Heading, HStack, Input, VStack } from "@chakra-ui/react";
import { CsvError, parse } from "csv-parse/browser/esm/sync";
import { TelcoCostCsvConfig } from "../../utils/csv/telcoCostCsvConfig";
import { TelcoCost } from "../../api";
import TelcoCostTableWithSearch from "../../components/TelcoCostTableWithSearch/TelcoCostTableWithSearch";
import { CostOverrideCostPlanSchemaType } from "./CostOverrideCostPlanForm";

export const CostPlanCostsSchema = z.object({
    activeImsiCost: z.number().min(0, "Active IMSI cost cannot be less than 0"),
    telcoCosts: z.array(
        z.object({
            countryCode: z.string(),
            plmn: z.string(),
            byteCost: z.number().optional(),
            mobileOriginatedCost: z.number().optional(),
            mobileTerminatedCost: z.number().optional(),
            networkAccessCost: z.number().optional(),
        }),
    ),
});

const CostPlanCostsFormStep = () => {
    const [costFile, setCostFile] = useState<File | null>(null);
    const notification = useNotifications();

    const {
        register,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext<CostOverrideCostPlanSchemaType>();
    const telcoCosts: TelcoCost[] = watch("telcoCosts", []);

    useEffect(() => {
        if (costFile) {
            costFile.text().then(csvText => {
                try {
                    const records = parse(csvText, {
                        columns: TelcoCostCsvConfig.columnParseConfig,
                        cast: TelcoCostCsvConfig.columnParseCastConfig,
                        delimiter: TelcoCostCsvConfig.delimiter,
                        skip_empty_lines: true,
                    });
                    setValue("telcoCosts", records);
                    notification.info("Costs Uploaded");
                } catch (err: unknown) {
                    if (err as CsvError) {
                        notification.error((err as CsvError).message);
                    } else {
                        notification.error("Error in CSV file");
                    }
                }
            });
        }
    }, [costFile, notification, setValue]);

    return (
        <VStack gap={4} w={"100%"} align={"stretch"} mb={6}>
            <FormContainer w={"60%"}>
                <HStack gap={4} align-items={"start"} pl={0} w={"100%"}>
                    <FormField label={"Active IMSI Cost"} error={errors?.activeImsiCost}>
                        <Input
                            id="activeImsiCost"
                            placeholder="Active IMSI Cost"
                            {...register("activeImsiCost", { valueAsNumber: true })}
                        />
                    </FormField>
                    <FormField label={"Upload costs"}>
                        <FileInput
                            name={"Cost File"}
                            placeholder={"Upload the cost file"}
                            acceptedFileTypes={"csv"}
                            value={costFile}
                            onChange={setCostFile}
                        />
                    </FormField>
                </HStack>
            </FormContainer>
            <Divider />
            <Heading size={"md"}>Uploaded Costs</Heading>
            <TelcoCostTableWithSearch telcoCosts={telcoCosts} />
            <Divider />
        </VStack>
    );
};

export default CostPlanCostsFormStep;
