import { Card, CardBody, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import React from "react";
import { RecurringFeeContextLabels } from "../../labels/RecurringFeeContextLabels";

export interface ConsumptionBasedPricingExplanationProps {
    context: "ACTIVE_SIM" | "ACTIVE_IMSI" | "INVENTORY_SIM" | "CUMULATIVE_ACTIVE_SIM_SET" | "IMSI_SWITCHES";
}

const ConsumptionBasedPricingExplanation = ({ context }: ConsumptionBasedPricingExplanationProps) => {
    const label = RecurringFeeContextLabels[context];
    return (
        <Card variant={"outline"}>
            <CardBody>
                <VStack w={"100%"} align={"stretch"} my={2}>
                    <Text>
                        Below is a simple explanation of the difference in pricing strategy, assuming that at the end of
                        the billing cycle the consumption was found to be <b>60</b> {label}.
                    </Text>
                    <Text>
                        If you have further questions regarding consumption based recurring fees, please reach out to
                        our support.
                    </Text>
                    <TableContainer w={"100%"}>
                        <Table size={"sm"} variant={"striped"} colorScheme={"primary"}>
                            <Thead>
                                <Tr>
                                    <Th colSpan={2}>Tiered Pricing</Th>
                                    <Th colSpan={2}>Volume Pricing</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td>0-10 {label}</Td>
                                    <Td>$20</Td>
                                    <Td>0-10 {label}</Td>
                                    <Td>$20</Td>
                                </Tr>
                                <Tr>
                                    <Td>11-30 {label}</Td>
                                    <Td>$10</Td>
                                    <Td>11-30 {label}</Td>
                                    <Td>$10</Td>
                                </Tr>
                                <Tr>
                                    <Td>31-100 {label}</Td>
                                    <Td>$5</Td>
                                    <Td>31-100 {label}</Td>
                                    <Td>$5</Td>
                                </Tr>
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>Total</Th>
                                    <Th>$550</Th>
                                    <Th>Total</Th>
                                    <Th>$300</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                </VStack>
            </CardBody>
        </Card>
    );
};

export default ConsumptionBasedPricingExplanation;
