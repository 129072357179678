import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { CostPlan, NetworkCredentialType } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { usePageNavigation, usePagination } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import CostPlanTable from "../../../../components/CostPlanTable/CostPlanTable";

interface CostPlansPanelProps {
    query: UseQueryResult<NetworkCredentialType>;
}

const CostPlansPanel = ({ query: { data: networkCredentialType } }: CostPlansPanelProps) => {
    const { configurationApi } = useApi();
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { navigate } = usePageNavigation<CostPlan>({
        route: value => `/configuration/networkcredentialtypes/${networkCredentialType?.id}/costplans/${value.id}`,
    });

    const costPlanQuery = useQuery(
        ["networkcredentialtypes", networkCredentialType?.id, "costplans"],
        () =>
            configurationApi.getNetworkCredentialTypeCostPlans({
                networkCredentialTypeId: networkCredentialType?.id || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
            }),
        { enabled: networkCredentialType?.id !== undefined },
    );

    return (
        <TabPanel w={"100%"}>
            <CostPlanTable
                costPlanQuery={costPlanQuery}
                pagination={{
                    pageIndex: pageState.pageIndex,
                    pageSize: pageState.pageSize,
                    onPaginationChange: setPageState,
                    totalRowCount: costPlanQuery?.data?.pageProperties?.totalElements || 0,
                    totalPageCount: costPlanQuery?.data?.pageProperties?.totalPages || 0,
                }}
                withRowClick={{
                    enableHoverStyle: true,
                    onRowClicked: navigate,
                }}
            />
        </TabPanel>
    );
};

export default CostPlansPanel;
