import {
    DateTime,
    FaIcon,
    Money,
    Month,
    monthToInt,
    Paper,
    PlaceholderText,
    SimCardIcon,
    TextColumn,
} from "@cobira/ui-library";
import { Grid, HStack, Icon, List, ListItem, Text, VStack } from "@chakra-ui/react";
import { DEFAULT_COST_CURRENCY } from "../../utils/pricingUtils";
import { CurrencyLabels } from "../../labels/CurrencyLabels";
import { CostPlanTypeLabels } from "../../labels/CostPlanTypeLabels";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { CostPlan } from "../../api";

export interface CostPlanDetailsProps {
    costPlan?: CostPlan;
    isLoading?: boolean;
}

const CostPlanDetails = ({ costPlan, isLoading }: CostPlanDetailsProps) => {
    return (
        <VStack w={"100%"} align={"stretch"} gap={6}>
            <Paper header={"Details"}>
                <Grid templateColumns="auto auto" gap="4">
                    <TextColumn heading="Name" grid isLoading={isLoading}>
                        {costPlan?.name}
                    </TextColumn>
                    <TextColumn heading="Description" grid isLoading={isLoading}>
                        <PlaceholderText text={costPlan?.description} />
                    </TextColumn>
                    <TextColumn heading="Active IMSI Cost" grid isLoading={isLoading}>
                        <Money value={costPlan?.activeImsiCost} decimals={4} currency={DEFAULT_COST_CURRENCY} />
                    </TextColumn>
                    <TextColumn heading={"Currency"} grid isLoading={isLoading}>
                        {CurrencyLabels["USD"]}
                    </TextColumn>
                    <TextColumn heading={"Applies from"} grid isLoading={isLoading}>
                        <DateTime
                            value={
                                costPlan?.validAt
                                    ? new Date(costPlan?.validAt.year, monthToInt(costPlan?.validAt.month as Month))
                                    : null
                            }
                            format={"MMMM, yyyy"}
                        />
                    </TextColumn>
                    <TextColumn heading={"Type"} grid isLoading={isLoading}>
                        {CostPlanTypeLabels[costPlan?.type || "BASE_PLAN"]}
                    </TextColumn>
                </Grid>
            </Paper>
            {costPlan?.type === "COST_OVERRIDE_PLAN" && (
                <Paper header={"Applicable SIM Ranges"}>
                    <List>
                        {costPlan?.costPlanOverrides?.map(override => (
                            <ListItem key={override.iccRange.iccStart}>
                                <HStack w={"100%"} gap={2} alignItems={"center"}>
                                    <Icon as={SimCardIcon} color={"primary.500"} h={"100%"} />
                                    <Text>{override.iccRange.iccStart}</Text>
                                    <FaIcon icon={faArrowRight} color={"primary.500"} h={"100%"} />
                                    <Text>{override.iccRange.iccEnd}</Text>
                                </HStack>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </VStack>
    );
};

export default CostPlanDetails;
