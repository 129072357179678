import React, { useMemo } from "react";
import { z } from "zod";
import { createWizardStep, intToMonth, Month, Wizard } from "@cobira/ui-library";
import CostPlanDetailsFormStep, { CostPlanDetailsSchema } from "./CostPlanDetailsFormStep";
import CostPlanCostsFormStep, { CostPlanCostsSchema } from "./CostPlanCostsFormStep";
import ConfirmBaseCostPlanStep from "./ConfirmBaseCostPlanStep";
import { CostPlan, TelcoCost } from "../../api";

const BaseCostPlanSchema = CostPlanDetailsSchema.merge(CostPlanCostsSchema);

export type BaseCostPlanSchemaType = z.infer<typeof BaseCostPlanSchema>;

export interface BaseCostPlanFormProps {
    onSubmit: (form: BaseCostPlanSchemaType) => void;
    defaultValue?: {
        costPlan?: CostPlan;
        telcoCosts?: TelcoCost[];
    };
}

const BaseCostPlanForm = ({ onSubmit, defaultValue }: BaseCostPlanFormProps) => {
    const formSteps = useMemo(
        () => [
            createWizardStep(<CostPlanDetailsFormStep />, CostPlanDetailsSchema, {
                stepComponent: "Details",
                defaultValue: {
                    name: defaultValue?.costPlan?.name || "",
                    description: defaultValue?.costPlan?.description,
                    appliesFrom: {
                        year: defaultValue?.costPlan?.validAt?.year || new Date().getUTCFullYear(),
                        month:
                            (defaultValue?.costPlan?.validAt?.month as Month) || intToMonth(new Date().getUTCMonth()),
                    },
                },
            }),
            createWizardStep(<CostPlanCostsFormStep />, CostPlanCostsSchema, {
                stepComponent: "Define Costs",
                defaultValue: {
                    telcoCosts: defaultValue?.telcoCosts || [],
                    activeImsiCost: defaultValue?.costPlan?.activeImsiCost || 0,
                },
            }),
        ],
        [defaultValue],
    );

    const confirmStep = useMemo(
        () =>
            createWizardStep(<ConfirmBaseCostPlanStep />, BaseCostPlanSchema, {
                stepComponent: "Confirm",
            }),
        [],
    );

    return <Wizard steps={formSteps} confirmStep={confirmStep} onSubmit={onSubmit} />;
};

export default BaseCostPlanForm;
