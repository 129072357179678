/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Action,
        AssignSimCards,
        ConsumptionState,
        CreateLabel,
        ESim,
        ESimPage,
        Hardware,
        ImeiLock,
        Labels,
        LocationUpdatePage,
        ManageSimCardCapability,
        NetworkCredential,
        NetworkCredentialSettings,
        NetworkState,
        NetworkStateRequest,
        PhoneNumber,
        SendSmsRequest,
        SetImeiLockStateAction,
        SetOverageActionRequest,
        SimCard,
        SimCardCapabilityName,
        SimCardNetworkState,
        SimCardPage,
        UpdateImeiLockEntry,
        UpdateSimCard,
        UsagePackage,
        UsagePackageManagementRequest,
    } from '../models';
        import {
            ActionFromJSON,
            ActionToJSON,
            AssignSimCardsFromJSON,
            AssignSimCardsToJSON,
            ConsumptionStateFromJSON,
            ConsumptionStateToJSON,
            CreateLabelFromJSON,
            CreateLabelToJSON,
            ESimFromJSON,
            ESimToJSON,
            ESimPageFromJSON,
            ESimPageToJSON,
            HardwareFromJSON,
            HardwareToJSON,
            ImeiLockFromJSON,
            ImeiLockToJSON,
            LabelsFromJSON,
            LabelsToJSON,
            LocationUpdatePageFromJSON,
            LocationUpdatePageToJSON,
            ManageSimCardCapabilityFromJSON,
            ManageSimCardCapabilityToJSON,
            NetworkCredentialFromJSON,
            NetworkCredentialToJSON,
            NetworkCredentialSettingsFromJSON,
            NetworkCredentialSettingsToJSON,
            NetworkStateFromJSON,
            NetworkStateToJSON,
            NetworkStateRequestFromJSON,
            NetworkStateRequestToJSON,
            PhoneNumberFromJSON,
            PhoneNumberToJSON,
            SendSmsRequestFromJSON,
            SendSmsRequestToJSON,
            SetImeiLockStateActionFromJSON,
            SetImeiLockStateActionToJSON,
            SetOverageActionRequestFromJSON,
            SetOverageActionRequestToJSON,
            SimCardFromJSON,
            SimCardToJSON,
            SimCardCapabilityNameFromJSON,
            SimCardCapabilityNameToJSON,
            SimCardNetworkStateFromJSON,
            SimCardNetworkStateToJSON,
            SimCardPageFromJSON,
            SimCardPageToJSON,
            UpdateImeiLockEntryFromJSON,
            UpdateImeiLockEntryToJSON,
            UpdateSimCardFromJSON,
            UpdateSimCardToJSON,
            UsagePackageFromJSON,
            UsagePackageToJSON,
            UsagePackageManagementRequestFromJSON,
            UsagePackageManagementRequestToJSON,
        } from '../models';

            export interface SimcardApiAssignSimCardRequest {
                assignSimCards: AssignSimCards;
            }

            export interface SimcardApiChangeSimCardNetworkStateRequest {
                networkStateRequest: NetworkStateRequest;
            }

            export interface SimcardApiCreateSimCardLabelRequest {
                icc: string;
                createLabel: CreateLabel;
            }

            export interface SimcardApiDeleteSimCardLabelRequest {
                icc: string;
                key: string;
            }

            export interface SimcardApiGetEsimRequest {
                esimId: string;
            }

            export interface SimcardApiGetEsimsRequest {
                pageSize: number;
                pageNumber: number;
            }

            export interface SimcardApiGetNetworkCredentialImeiLocksRequest {
                icc: string;
                imsi: string;
            }

            export interface SimcardApiGetNetworkCredentialPhoneNumbersRequest {
                icc: string;
                imsi: string;
            }

            export interface SimcardApiGetNetworkCredentialSettingsRequest {
                icc: string;
                imsi: string;
            }

            export interface SimcardApiGetSimCardRequest {
                icc: string;
            }

            export interface SimcardApiGetSimCardHardwareRequest {
                icc: string;
            }

            export interface SimcardApiGetSimCardLabelsRequest {
                icc: string;
            }

            export interface SimcardApiGetSimCardLocationUpdatesRequest {
                icc: string;
                pageNumber: number;
                pageSize: number;
                fromDate?: Date;
                toDate?: Date;
            }

            export interface SimcardApiGetSimCardNetworkCredentialsRequest {
                icc: string;
            }

            export interface SimcardApiGetSimCardNetworkStateRequest {
                icc: string;
            }

            export interface SimcardApiGetSimCardUsagePackagesRequest {
                icc: string;
            }

            export interface SimcardApiGetSimCardsRequest {
                pageNumber: number;
                pageSize: number;
                iccLowerBound?: string;
                iccUpperBound?: string;
                msisdnSearch?: string;
                iccSet?: Set<string>;
                billingGroupIdSet?: Set<string>;
                search?: string;
                networkState?: NetworkState;
                customerId?: string;
                labels?: string | null;
                consumptionState?: ConsumptionState;
                simConfigurationSearch?: string;
                networkCredentialTypeSearch?: string;
                country?: string;
                plmnSearch?: string;
                usagePackageTypeSearch?: string;
                simCapability?: SimCardCapabilityName;
                isMultiImsi?: boolean | null;
            }

            export interface SimcardApiManageSimCardCapabilityRequest {
                manageSimCardCapability: ManageSimCardCapability;
            }

            export interface SimcardApiManageSimCardUsagePackagesRequest {
                usagePackageManagementRequest: UsagePackageManagementRequest;
            }

            export interface SimcardApiPatchSimCardDetailsRequest {
                icc: string;
                updateSimCard: UpdateSimCard;
            }

            export interface SimcardApiSendSmsToSimCardsRequest {
                sendSmsRequest: SendSmsRequest;
            }

            export interface SimcardApiSetImeiLockStatesRequest {
                setImeiLockStateAction: SetImeiLockStateAction;
            }

            export interface SimcardApiSetSimCardOverageRequest {
                setOverageActionRequest: SetOverageActionRequest;
            }

            export interface SimcardApiUpdateImeiLocksRequest {
                updateImeiLockEntry: Array<UpdateImeiLockEntry>;
            }

        /**
        * 
        */
            export class SimcardApi extends runtime.BaseAPI {

            /**
                * Assign a range of sim cards to the specified tenant (or yourself if you leave it out i.e. a move operation) Similarly the billing group is optional if you are assigning the cards to one of your tenants, if you leave it out the cards will be assigned to the default billing group of that tenant.
                * Assign sim cards
            */
            async assignSimCardRaw(requestParameters: SimcardApiAssignSimCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.assignSimCards === null || requestParameters.assignSimCards === undefined) {
                    throw new runtime.RequiredError('assignSimCards','Required parameter requestParameters.assignSimCards was null or undefined when calling assignSimCard.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/assignsimcard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: AssignSimCardsToJSON(requestParameters.assignSimCards),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Assign a range of sim cards to the specified tenant (or yourself if you leave it out i.e. a move operation) Similarly the billing group is optional if you are assigning the cards to one of your tenants, if you leave it out the cards will be assigned to the default billing group of that tenant.
                * Assign sim cards
            */
                async assignSimCard(requestParameters: SimcardApiAssignSimCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.assignSimCardRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Perform a network state request against the filtered list of sim cards. The operation will be executed against all associated network credentials.  *ACTIVATE*  The sim card will be allowed to attach to networks according to its configuration.  *SUSPEND*  The sim card will not be allowed to attach to any networks.  *RESET*  Performs a reattachment of the sim card, causing it to reestablish its data session, if any.
                * Change sim card network state
            */
            async changeSimCardNetworkStateRaw(requestParameters: SimcardApiChangeSimCardNetworkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.networkStateRequest === null || requestParameters.networkStateRequest === undefined) {
                    throw new runtime.RequiredError('networkStateRequest','Required parameter requestParameters.networkStateRequest was null or undefined when calling changeSimCardNetworkState.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/changenetworkstate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: NetworkStateRequestToJSON(requestParameters.networkStateRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Perform a network state request against the filtered list of sim cards. The operation will be executed against all associated network credentials.  *ACTIVATE*  The sim card will be allowed to attach to networks according to its configuration.  *SUSPEND*  The sim card will not be allowed to attach to any networks.  *RESET*  Performs a reattachment of the sim card, causing it to reestablish its data session, if any.
                * Change sim card network state
            */
                async changeSimCardNetworkState(requestParameters: SimcardApiChangeSimCardNetworkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.changeSimCardNetworkStateRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create new label and associate with SIM.
                * Create SIM Card Label
            */
            async createSimCardLabelRaw(requestParameters: SimcardApiCreateSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling createSimCardLabel.');
                    }

                    if (requestParameters.createLabel === null || requestParameters.createLabel === undefined) {
                    throw new runtime.RequiredError('createLabel','Required parameter requestParameters.createLabel was null or undefined when calling createSimCardLabel.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/labels`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateLabelToJSON(requestParameters.createLabel),
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Create new label and associate with SIM.
                * Create SIM Card Label
            */
                async createSimCardLabel(requestParameters: SimcardApiCreateSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.createSimCardLabelRaw(requestParameters, initOverrides);
                }

            /**
                * Delete label associated with SIM.
                * Delete SIM Card Label
            */
            async deleteSimCardLabelRaw(requestParameters: SimcardApiDeleteSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling deleteSimCardLabel.');
                    }

                    if (requestParameters.key === null || requestParameters.key === undefined) {
                    throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling deleteSimCardLabel.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/labels/{key}`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Delete label associated with SIM.
                * Delete SIM Card Label
            */
                async deleteSimCardLabel(requestParameters: SimcardApiDeleteSimCardLabelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteSimCardLabelRaw(requestParameters, initOverrides);
                }

            /**
                * Get ESIM
            */
            async getEsimRaw(requestParameters: SimcardApiGetEsimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ESim>> {
                    if (requestParameters.esimId === null || requestParameters.esimId === undefined) {
                    throw new runtime.RequiredError('esimId','Required parameter requestParameters.esimId was null or undefined when calling getEsim.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/esims/{esimId}`.replace(`{${"esimId"}}`, encodeURIComponent(String(requestParameters.esimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ESimFromJSON(jsonValue));
            }

            /**
                * Get ESIM
            */
                async getEsim(requestParameters: SimcardApiGetEsimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ESim> {
                    const response = await this.getEsimRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * 
                * Get all ESIMs
            */
            async getEsimsRaw(requestParameters: SimcardApiGetEsimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ESimPage>> {
                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getEsims.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getEsims.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/esims`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ESimPageFromJSON(jsonValue));
            }

            /**
                * 
                * Get all ESIMs
            */
                async getEsims(requestParameters: SimcardApiGetEsimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ESimPage> {
                    const response = await this.getEsimsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get configured IMEI locks for a network credential. These will be enforced based on the value of the IMEI lock violation behaviour in the network credential settings.
                * Get IMEI locks for a network credential
            */
            async getNetworkCredentialImeiLocksRaw(requestParameters: SimcardApiGetNetworkCredentialImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ImeiLock>>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getNetworkCredentialImeiLocks.');
                    }

                    if (requestParameters.imsi === null || requestParameters.imsi === undefined) {
                    throw new runtime.RequiredError('imsi','Required parameter requestParameters.imsi was null or undefined when calling getNetworkCredentialImeiLocks.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkcredentials/{imsi}/imeilocks`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))).replace(`{${"imsi"}}`, encodeURIComponent(String(requestParameters.imsi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImeiLockFromJSON));
            }

            /**
                * Get configured IMEI locks for a network credential. These will be enforced based on the value of the IMEI lock violation behaviour in the network credential settings.
                * Get IMEI locks for a network credential
            */
                async getNetworkCredentialImeiLocks(requestParameters: SimcardApiGetNetworkCredentialImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ImeiLock>> {
                    const response = await this.getNetworkCredentialImeiLocksRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all phone numbers assigned to the requested network credential.
                * Get network credential phone numbers
            */
            async getNetworkCredentialPhoneNumbersRaw(requestParameters: SimcardApiGetNetworkCredentialPhoneNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PhoneNumber>>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getNetworkCredentialPhoneNumbers.');
                    }

                    if (requestParameters.imsi === null || requestParameters.imsi === undefined) {
                    throw new runtime.RequiredError('imsi','Required parameter requestParameters.imsi was null or undefined when calling getNetworkCredentialPhoneNumbers.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkcredentials/{imsi}/phonenumbers`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))).replace(`{${"imsi"}}`, encodeURIComponent(String(requestParameters.imsi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneNumberFromJSON));
            }

            /**
                * Get all phone numbers assigned to the requested network credential.
                * Get network credential phone numbers
            */
                async getNetworkCredentialPhoneNumbers(requestParameters: SimcardApiGetNetworkCredentialPhoneNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PhoneNumber>> {
                    const response = await this.getNetworkCredentialPhoneNumbersRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get the settings for a single network credential.
                * Get network credential settings
            */
            async getNetworkCredentialSettingsRaw(requestParameters: SimcardApiGetNetworkCredentialSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialSettings>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getNetworkCredentialSettings.');
                    }

                    if (requestParameters.imsi === null || requestParameters.imsi === undefined) {
                    throw new runtime.RequiredError('imsi','Required parameter requestParameters.imsi was null or undefined when calling getNetworkCredentialSettings.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkcredentials/{imsi}/settings`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))).replace(`{${"imsi"}}`, encodeURIComponent(String(requestParameters.imsi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialSettingsFromJSON(jsonValue));
            }

            /**
                * Get the settings for a single network credential.
                * Get network credential settings
            */
                async getNetworkCredentialSettings(requestParameters: SimcardApiGetNetworkCredentialSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialSettings> {
                    const response = await this.getNetworkCredentialSettingsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single SIM card by its ICC number
                * Get SIM card
            */
            async getSimCardRaw(requestParameters: SimcardApiGetSimCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCard>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCard.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardFromJSON(jsonValue));
            }

            /**
                * Get a single SIM card by its ICC number
                * Get SIM card
            */
                async getSimCard(requestParameters: SimcardApiGetSimCardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCard> {
                    const response = await this.getSimCardRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch the stored hardware information for a SIM Card. This can be fetched by invoking the `HARDWARE_REGISTRATION` capability. Hardware related capabilities are not available for all SIM Cards and for that reason the information may be missing when requested.
                * Get SIM Card Hardware
            */
            async getSimCardHardwareRaw(requestParameters: SimcardApiGetSimCardHardwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Hardware>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardHardware.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/hardware`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => HardwareFromJSON(jsonValue));
            }

            /**
                * Fetch the stored hardware information for a SIM Card. This can be fetched by invoking the `HARDWARE_REGISTRATION` capability. Hardware related capabilities are not available for all SIM Cards and for that reason the information may be missing when requested.
                * Get SIM Card Hardware
            */
                async getSimCardHardware(requestParameters: SimcardApiGetSimCardHardwareRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Hardware> {
                    const response = await this.getSimCardHardwareRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get labels associated with SIM.
                * Get SIM Card Labels
            */
            async getSimCardLabelsRaw(requestParameters: SimcardApiGetSimCardLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Labels>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardLabels.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/labels`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => LabelsFromJSON(jsonValue));
            }

            /**
                * Get labels associated with SIM.
                * Get SIM Card Labels
            */
                async getSimCardLabels(requestParameters: SimcardApiGetSimCardLabelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Labels> {
                    const response = await this.getSimCardLabelsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get location updates registered for the requested SIM card
                * Get SIM card location updates
            */
            async getSimCardLocationUpdatesRaw(requestParameters: SimcardApiGetSimCardLocationUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationUpdatePage>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardLocationUpdates.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimCardLocationUpdates.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimCardLocationUpdates.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.fromDate !== undefined) {
                            queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString().substr(0,10);
                    }

                    if (requestParameters.toDate !== undefined) {
                            queryParameters['toDate'] = (requestParameters.toDate as any).toISOString().substr(0,10);
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/locationupdates`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => LocationUpdatePageFromJSON(jsonValue));
            }

            /**
                * Get location updates registered for the requested SIM card
                * Get SIM card location updates
            */
                async getSimCardLocationUpdates(requestParameters: SimcardApiGetSimCardLocationUpdatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationUpdatePage> {
                    const response = await this.getSimCardLocationUpdatesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all network credentials for a given SIM card.
                * Get network credentials for a SIM card
            */
            async getSimCardNetworkCredentialsRaw(requestParameters: SimcardApiGetSimCardNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NetworkCredential>>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardNetworkCredentials.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkcredentials`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NetworkCredentialFromJSON));
            }

            /**
                * Get all network credentials for a given SIM card.
                * Get network credentials for a SIM card
            */
                async getSimCardNetworkCredentials(requestParameters: SimcardApiGetSimCardNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NetworkCredential>> {
                    const response = await this.getSimCardNetworkCredentialsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get network state for the SIM card.  The network state represents the latest recorded information across all network credentials for the SIM card.
                * Get SIM card network state
            */
            async getSimCardNetworkStateRaw(requestParameters: SimcardApiGetSimCardNetworkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCardNetworkState>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardNetworkState.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkstate`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardNetworkStateFromJSON(jsonValue));
            }

            /**
                * Get network state for the SIM card.  The network state represents the latest recorded information across all network credentials for the SIM card.
                * Get SIM card network state
            */
                async getSimCardNetworkState(requestParameters: SimcardApiGetSimCardNetworkStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCardNetworkState> {
                    const response = await this.getSimCardNetworkStateRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all usage packages assigned to the SIM card, both `IDLE` and `ACTIVE` as well as `TERMINATED` usage packages.
                * Get SIM card usage packages
            */
            async getSimCardUsagePackagesRaw(requestParameters: SimcardApiGetSimCardUsagePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UsagePackage>>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardUsagePackages.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/usagepackages`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsagePackageFromJSON));
            }

            /**
                * Get all usage packages assigned to the SIM card, both `IDLE` and `ACTIVE` as well as `TERMINATED` usage packages.
                * Get SIM card usage packages
            */
                async getSimCardUsagePackages(requestParameters: SimcardApiGetSimCardUsagePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UsagePackage>> {
                    const response = await this.getSimCardUsagePackagesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Query for sim cards.  The supported range filters are inclusive. All parameters applied are combined using `and`.
                * Get all assigned sim cards
            */
            async getSimCardsRaw(requestParameters: SimcardApiGetSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCardPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getSimCards.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getSimCards.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.iccLowerBound !== undefined) {
                            queryParameters['iccLowerBound'] = requestParameters.iccLowerBound;
                    }

                    if (requestParameters.iccUpperBound !== undefined) {
                            queryParameters['iccUpperBound'] = requestParameters.iccUpperBound;
                    }

                    if (requestParameters.msisdnSearch !== undefined) {
                            queryParameters['msisdnSearch'] = requestParameters.msisdnSearch;
                    }

                    if (requestParameters.iccSet) {
                        queryParameters['iccSet'] = requestParameters.iccSet;
                    }

                    if (requestParameters.billingGroupIdSet) {
                        queryParameters['billingGroupIdSet'] = requestParameters.billingGroupIdSet;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.networkState !== undefined) {
                            queryParameters['networkState'] = requestParameters.networkState;
                    }

                    if (requestParameters.customerId !== undefined) {
                            queryParameters['customerId'] = requestParameters.customerId;
                    }

                    if (requestParameters.labels !== undefined) {
                            queryParameters['labels'] = requestParameters.labels;
                    }

                    if (requestParameters.consumptionState !== undefined) {
                            queryParameters['consumptionState'] = requestParameters.consumptionState;
                    }

                    if (requestParameters.simConfigurationSearch !== undefined) {
                            queryParameters['simConfigurationSearch'] = requestParameters.simConfigurationSearch;
                    }

                    if (requestParameters.networkCredentialTypeSearch !== undefined) {
                            queryParameters['networkCredentialTypeSearch'] = requestParameters.networkCredentialTypeSearch;
                    }

                    if (requestParameters.country !== undefined) {
                            queryParameters['country'] = requestParameters.country;
                    }

                    if (requestParameters.plmnSearch !== undefined) {
                            queryParameters['plmnSearch'] = requestParameters.plmnSearch;
                    }

                    if (requestParameters.usagePackageTypeSearch !== undefined) {
                            queryParameters['usagePackageTypeSearch'] = requestParameters.usagePackageTypeSearch;
                    }

                    if (requestParameters.simCapability !== undefined) {
                            queryParameters['simCapability'] = requestParameters.simCapability;
                    }

                    if (requestParameters.isMultiImsi !== undefined) {
                            queryParameters['isMultiImsi'] = requestParameters.isMultiImsi;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardPageFromJSON(jsonValue));
            }

            /**
                * Query for sim cards.  The supported range filters are inclusive. All parameters applied are combined using `and`.
                * Get all assigned sim cards
            */
                async getSimCards(requestParameters: SimcardApiGetSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCardPage> {
                    const response = await this.getSimCardsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Activate, deactivate, or invoke SIM card capability
                * Manage SIM card capability
            */
            async manageSimCardCapabilityRaw(requestParameters: SimcardApiManageSimCardCapabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.manageSimCardCapability === null || requestParameters.manageSimCardCapability === undefined) {
                    throw new runtime.RequiredError('manageSimCardCapability','Required parameter requestParameters.manageSimCardCapability was null or undefined when calling manageSimCardCapability.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/managecapability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: ManageSimCardCapabilityToJSON(requestParameters.manageSimCardCapability),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Activate, deactivate, or invoke SIM card capability
                * Manage SIM card capability
            */
                async manageSimCardCapability(requestParameters: SimcardApiManageSimCardCapabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.manageSimCardCapabilityRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Perform a usage package management operation against the filtered list of sim cards. The operation will be executed against all associated network credentials.  *ASSIGN*  After the assign operation succeeds, the sim card will only be allowed to exhaust the assigned packages, after which it will no longer be allowed to use any network traffic.  *REVOKE*  Revokes any instance of the specified usage package type currently active.
                * Manage sim card usage packages
            */
            async manageSimCardUsagePackagesRaw(requestParameters: SimcardApiManageSimCardUsagePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.usagePackageManagementRequest === null || requestParameters.usagePackageManagementRequest === undefined) {
                    throw new runtime.RequiredError('usagePackageManagementRequest','Required parameter requestParameters.usagePackageManagementRequest was null or undefined when calling manageSimCardUsagePackages.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/manageusagepackages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: UsagePackageManagementRequestToJSON(requestParameters.usagePackageManagementRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Perform a usage package management operation against the filtered list of sim cards. The operation will be executed against all associated network credentials.  *ASSIGN*  After the assign operation succeeds, the sim card will only be allowed to exhaust the assigned packages, after which it will no longer be allowed to use any network traffic.  *REVOKE*  Revokes any instance of the specified usage package type currently active.
                * Manage sim card usage packages
            */
                async manageSimCardUsagePackages(requestParameters: SimcardApiManageSimCardUsagePackagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.manageSimCardUsagePackagesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Update mutable SIM card details. For now this includes only the custom name of the SIM card.
                * Update SIM card details
            */
            async patchSimCardDetailsRaw(requestParameters: SimcardApiPatchSimCardDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimCard>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling patchSimCardDetails.');
                    }

                    if (requestParameters.updateSimCard === null || requestParameters.updateSimCard === undefined) {
                    throw new runtime.RequiredError('updateSimCard','Required parameter requestParameters.updateSimCard was null or undefined when calling patchSimCardDetails.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateSimCardToJSON(requestParameters.updateSimCard),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => SimCardFromJSON(jsonValue));
            }

            /**
                * Update mutable SIM card details. For now this includes only the custom name of the SIM card.
                * Update SIM card details
            */
                async patchSimCardDetails(requestParameters: SimcardApiPatchSimCardDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimCard> {
                    const response = await this.patchSimCardDetailsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Attempt to send an SMS to all the SIM Cards found in the filter. The selected MSISDN for each ICC can be specified, but if not specified, an appropriate MSISDN will be selected if any are available.
                * Send an SMS to the SIM Cards
            */
            async sendSmsToSimCardsRaw(requestParameters: SimcardApiSendSmsToSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.sendSmsRequest === null || requestParameters.sendSmsRequest === undefined) {
                    throw new runtime.RequiredError('sendSmsRequest','Required parameter requestParameters.sendSmsRequest was null or undefined when calling sendSmsToSimCards.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/sendsms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: SendSmsRequestToJSON(requestParameters.sendSmsRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Attempt to send an SMS to all the SIM Cards found in the filter. The selected MSISDN for each ICC can be specified, but if not specified, an appropriate MSISDN will be selected if any are available.
                * Send an SMS to the SIM Cards
            */
                async sendSmsToSimCards(requestParameters: SimcardApiSendSmsToSimCardsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.sendSmsToSimCardsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Sets the IMEI lock state for the selected SIM cards.  This will only control the IMEI lock behaviour and state, if you need to insert specific IMEIs for SIM cards please use the `/actions/simcards/updateimeilock` endpoint.
                * Set IMEI lock state
            */
            async setImeiLockStatesRaw(requestParameters: SimcardApiSetImeiLockStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.setImeiLockStateAction === null || requestParameters.setImeiLockStateAction === undefined) {
                    throw new runtime.RequiredError('setImeiLockStateAction','Required parameter requestParameters.setImeiLockStateAction was null or undefined when calling setImeiLockStates.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/setimeilockstates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: SetImeiLockStateActionToJSON(requestParameters.setImeiLockStateAction),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Sets the IMEI lock state for the selected SIM cards.  This will only control the IMEI lock behaviour and state, if you need to insert specific IMEIs for SIM cards please use the `/actions/simcards/updateimeilock` endpoint.
                * Set IMEI lock state
            */
                async setImeiLockStates(requestParameters: SimcardApiSetImeiLockStatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.setImeiLockStatesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Allows modifying the \"overage\" state of SIM cards.  Broadly speaking:  - `ALLOWED`: Supports a pay-as-you-go model. - `DISALLOWED`: Requires usage packages; Disables any unsanctioned usage by the SIMs.
                * Set SIM Card Overage
            */
            async setSimCardOverageRaw(requestParameters: SimcardApiSetSimCardOverageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.setOverageActionRequest === null || requestParameters.setOverageActionRequest === undefined) {
                    throw new runtime.RequiredError('setOverageActionRequest','Required parameter requestParameters.setOverageActionRequest was null or undefined when calling setSimCardOverage.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/setoverage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: SetOverageActionRequestToJSON(requestParameters.setOverageActionRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Allows modifying the \"overage\" state of SIM cards.  Broadly speaking:  - `ALLOWED`: Supports a pay-as-you-go model. - `DISALLOWED`: Requires usage packages; Disables any unsanctioned usage by the SIMs.
                * Set SIM Card Overage
            */
                async setSimCardOverage(requestParameters: SimcardApiSetSimCardOverageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.setSimCardOverageRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create or delete IMEI lock entries for a set of selected SIM cards.  The added IMEI will not be enforced unless the IMEI lock state is to either `CHECK` or `CHECK_AND_SET`. This is done using the endpoint `/actions/simcards/setimeilockstate`.
                * Update IMEI locks
            */
            async updateImeiLocksRaw(requestParameters: SimcardApiUpdateImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.updateImeiLockEntry === null || requestParameters.updateImeiLockEntry === undefined) {
                    throw new runtime.RequiredError('updateImeiLockEntry','Required parameter requestParameters.updateImeiLockEntry was null or undefined when calling updateImeiLocks.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/simcards/updateimeilocks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                            body: requestParameters.updateImeiLockEntry.map(UpdateImeiLockEntryToJSON),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Create or delete IMEI lock entries for a set of selected SIM cards.  The added IMEI will not be enforced unless the IMEI lock state is to either `CHECK` or `CHECK_AND_SET`. This is done using the endpoint `/actions/simcards/setimeilockstate`.
                * Update IMEI locks
            */
                async updateImeiLocks(requestParameters: SimcardApiUpdateImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.updateImeiLocksRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
