import React from "react";
import { PageBackButton, PageLayout, PageTitle } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import UsagePackageTypeDetails from "../../../components/UsagePackageTypeDetails/UsagePackageTypeDetails";

const TenantUsagePackageTypeDetailPage = () => {
    const { tenantApi } = useApi();
    const { tenantId, usagePackageTypeId } = useParams();

    const usagePackageTypeQuery = useQuery(
        ["tenants", tenantId, "usagepackagetypes", usagePackageTypeId],
        () =>
            tenantApi.getTenantUsagePackageType({
                tenantId: tenantId || "",
                usagePackageTypeId: usagePackageTypeId || "",
            }),
        {
            enabled: !!usagePackageTypeId && !!tenantId,
        },
    );

    return (
        <PageLayout
            title={<PageTitle title={usagePackageTypeQuery?.data?.customName || "Bundle"} />}
            navigation={<PageBackButton text={"Back to reseller"} path={"/"} />}
        >
            <UsagePackageTypeDetails query={usagePackageTypeQuery} />
        </PageLayout>
    );
};

export default TenantUsagePackageTypeDetailPage;
