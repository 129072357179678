import React from "react";
import { useFormContext } from "react-hook-form";
import { Button, Divider, GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { DateTime, Money, PlaceholderText, TextColumn, yearMonthToDate } from "@cobira/ui-library";
import { DEFAULT_COST_CURRENCY } from "../../utils/pricingUtils";
import { BaseCostPlanSchemaType } from "./BaseCostPlanForm";
import TelcoCostTable from "../../components/TelcoCostTableWithSearch/TelcoCostTable";

const ConfirmCostOverrideCostPlanStep = () => {
    const {
        getValues,
        formState: { isSubmitting },
    } = useFormContext<BaseCostPlanSchemaType>();
    const formValues = getValues();
    return (
        <VStack gap={4} mt={4}>
            <SimpleGrid columns={2} w={"100%"}>
                <GridItem>
                    <VStack gap={4} alignItems={"start"}>
                        <TextColumn heading={"Name"}>
                            <Text>{formValues.name}</Text>
                        </TextColumn>
                        <TextColumn heading={"Description"}>
                            <PlaceholderText text={formValues.description} />
                        </TextColumn>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack gap={4} alignItems={"start"}>
                        <TextColumn heading={"Applies From"}>
                            <DateTime value={yearMonthToDate(formValues.appliesFrom)} format={"MMMM, yyyy"} />
                        </TextColumn>
                        <TextColumn heading={"Active IMSI Cost"}>
                            <Money value={formValues.activeImsiCost} currency={DEFAULT_COST_CURRENCY} decimals={4} />
                        </TextColumn>
                    </VStack>
                </GridItem>
            </SimpleGrid>
            <Divider />
            <TelcoCostTable telcoCosts={formValues.telcoCosts} />
            <Divider />
            <Button isLoading={isSubmitting} isDisabled={isSubmitting} type="submit" alignSelf={"start"}>
                Submit Cost Plan
            </Button>
        </VStack>
    );
};

export default ConfirmCostOverrideCostPlanStep;
