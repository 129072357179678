/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostPlanCostOverride } from './CostPlanCostOverride';
import {
    CostPlanCostOverrideFromJSON,
    CostPlanCostOverrideFromJSONTyped,
    CostPlanCostOverrideToJSON,
} from './CostPlanCostOverride';
import type { TelcoCost } from './TelcoCost';
import {
    TelcoCostFromJSON,
    TelcoCostFromJSONTyped,
    TelcoCostToJSON,
} from './TelcoCost';
import type { YearMonth } from './YearMonth';
import {
    YearMonthFromJSON,
    YearMonthFromJSONTyped,
    YearMonthToJSON,
} from './YearMonth';

/**
 * Creates a new cost plan, if the `costPlanOverrides` property is used, the plan will only apply to the selected overrides, otherwise it will function as a base plan.
 * @export
 * @interface CreateCostPlan
 */
export interface CreateCostPlan {
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCostPlan
     */
    description?: string;
    /**
     * 
     * @type {YearMonth}
     * @memberof CreateCostPlan
     */
    validAt: YearMonth;
    /**
     * 
     * @type {Array<CostPlanCostOverride>}
     * @memberof CreateCostPlan
     */
    costPlanOverrides?: Array<CostPlanCostOverride> | null;
    /**
     * 
     * @type {Array<TelcoCost>}
     * @memberof CreateCostPlan
     */
    telcoCosts: Array<TelcoCost>;
    /**
     * 
     * @type {number}
     * @memberof CreateCostPlan
     */
    activeImsiCost: number;
}

/**
 * Check if a given object implements the CreateCostPlan interface.
 */
export function instanceOfCreateCostPlan(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "validAt" in value;
    isInstance = isInstance && "telcoCosts" in value;
    isInstance = isInstance && "activeImsiCost" in value;

    return isInstance;
}

export function CreateCostPlanFromJSON(json: any): CreateCostPlan {
    return CreateCostPlanFromJSONTyped(json, false);
}

export function CreateCostPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCostPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'validAt': YearMonthFromJSON(json['validAt']),
        'costPlanOverrides': !exists(json, 'costPlanOverrides') ? undefined : (json['costPlanOverrides'] === null ? null : (json['costPlanOverrides'] as Array<any>).map(CostPlanCostOverrideFromJSON)),
        'telcoCosts': ((json['telcoCosts'] as Array<any>).map(TelcoCostFromJSON)),
        'activeImsiCost': json['activeImsiCost'],
    };
}

export function CreateCostPlanToJSON(value?: CreateCostPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'validAt': YearMonthToJSON(value.validAt),
        'costPlanOverrides': value.costPlanOverrides === undefined ? undefined : (value.costPlanOverrides === null ? null : (value.costPlanOverrides as Array<any>).map(CostPlanCostOverrideToJSON)),
        'telcoCosts': ((value.telcoCosts as Array<any>).map(TelcoCostToJSON)),
        'activeImsiCost': value.activeImsiCost,
    };
}

