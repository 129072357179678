/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelcoCost
 */
export interface TelcoCost {
    /**
     * 
     * @type {string}
     * @memberof TelcoCost
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof TelcoCost
     */
    countryCode: string;
    /**
     * 
     * @type {number}
     * @memberof TelcoCost
     */
    byteCost?: number;
    /**
     * 
     * @type {number}
     * @memberof TelcoCost
     */
    mobileOriginatedCost?: number;
    /**
     * 
     * @type {number}
     * @memberof TelcoCost
     */
    mobileTerminatedCost?: number;
    /**
     * 
     * @type {number}
     * @memberof TelcoCost
     */
    networkAccessCost?: number;
}

/**
 * Check if a given object implements the TelcoCost interface.
 */
export function instanceOfTelcoCost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "countryCode" in value;

    return isInstance;
}

export function TelcoCostFromJSON(json: any): TelcoCost {
    return TelcoCostFromJSONTyped(json, false);
}

export function TelcoCostFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelcoCost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'plmn': json['plmn'],
        'countryCode': json['countryCode'],
        'byteCost': !exists(json, 'byteCost') ? undefined : json['byteCost'],
        'mobileOriginatedCost': !exists(json, 'mobileOriginatedCost') ? undefined : json['mobileOriginatedCost'],
        'mobileTerminatedCost': !exists(json, 'mobileTerminatedCost') ? undefined : json['mobileTerminatedCost'],
        'networkAccessCost': !exists(json, 'networkAccessCost') ? undefined : json['networkAccessCost'],
    };
}

export function TelcoCostToJSON(value?: TelcoCost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'plmn': value.plmn,
        'countryCode': value.countryCode,
        'byteCost': value.byteCost,
        'mobileOriginatedCost': value.mobileOriginatedCost,
        'mobileTerminatedCost': value.mobileTerminatedCost,
        'networkAccessCost': value.networkAccessCost,
    };
}

