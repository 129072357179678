import React, { useCallback } from "react";
import { useParams } from "react-router";
import { Loading, PageBackButton, PageLayout, PageTitle, Paper, useNotifications } from "@cobira/ui-library";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import {
    CostPlan,
    TenantApiUpdateTenantNetworkCredentialTypeCostPlanRequest,
    TenantApiUpdateTenantNetworkCredentialTypeCostPlanTelcoCostsRequest,
} from "../../../../api";
import BaseCostPlanForm, { BaseCostPlanSchemaType } from "../../../../forms/CostPlanForm/BaseCostPlanForm";
import CostOverrideCostPlanForm, {
    CostOverrideCostPlanSchemaType,
} from "../../../../forms/CostPlanForm/CostOverrideCostPlanForm";

const UpdateCostPlanPage = () => {
    const { tenantId, networkCredentialTypeId, costPlanId } = useParams();
    const { tenantApi } = useApi();
    const notifications = useNotifications();
    const queryClient = useQueryClient();
    const costPlanQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId, "costplans", costPlanId],
        () =>
            tenantApi.getTenantNetworkCredentialTypeCostPlan({
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
                tenantId: tenantId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!costPlanId && !!tenantId,
        },
    );

    const telcoCostsQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId, "costplans", costPlanId, "telcocosts"],
        () =>
            tenantApi.getTenantNetworkCredentialTypeCostPlanTelcoCosts({
                tenantId: tenantId || "",
                costPlanId: costPlanId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
                pageNumber: 0,
                pageSize: 10000,
            }),
        { enabled: !!costPlanId && !!networkCredentialTypeId },
    );

    const updateCostPlan = useMutation<CostPlan, Error, TenantApiUpdateTenantNetworkCredentialTypeCostPlanRequest>(
        variables => tenantApi.updateTenantNetworkCredentialTypeCostPlan(variables),
        {
            onSuccess: data => {
                notifications.success(`Plan '${data.name}' updated`);
                queryClient.invalidateQueries([
                    "tenants",
                    tenantId,
                    "networkcredentialtypes",
                    networkCredentialTypeId,
                    "costplans",
                    costPlanId,
                ]);
            },
        },
    );

    const updateCostPlanCosts = useMutation<
        void,
        Error,
        TenantApiUpdateTenantNetworkCredentialTypeCostPlanTelcoCostsRequest
    >(variables => tenantApi.updateTenantNetworkCredentialTypeCostPlanTelcoCosts(variables), {
        onSuccess: () => {
            notifications.success(`Costs updated`);
            queryClient.invalidateQueries([
                "tenants",
                tenantId,
                "networkcredentialtypes",
                networkCredentialTypeId,
                "costplans",
                costPlanId,
                "costs",
            ]);
        },
    });

    const handleBaseCostPlanSubmit = useCallback(
        (form: BaseCostPlanSchemaType) => {
            updateCostPlan.mutate({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
                updateCostPlan: {
                    name: form.name,
                    description: form.description,
                    activeImsiCost: form.activeImsiCost,
                    validAt: form.appliesFrom,
                    costPlanOverrides: undefined,
                },
            });
            updateCostPlanCosts.mutate({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
                telcoCost: form.telcoCosts,
            });
        },
        [updateCostPlan, tenantId, networkCredentialTypeId, costPlanId, updateCostPlanCosts],
    );

    const handleCostOverrideSubmit = useCallback(
        (form: CostOverrideCostPlanSchemaType) => {
            updateCostPlan.mutate({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
                updateCostPlan: {
                    name: form.name,
                    description: form.description,
                    activeImsiCost: form.activeImsiCost,
                    validAt: form.appliesFrom,
                    costPlanOverrides: form.iccRanges.map(iccRange => ({
                        iccRange: {
                            iccStart: iccRange.iccFrom,
                            iccEnd: iccRange.iccTo,
                        },
                    })),
                },
            });
            updateCostPlanCosts.mutate({
                tenantId: tenantId || "",
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
                telcoCost: form.telcoCosts,
            });
        },
        [updateCostPlan, tenantId, networkCredentialTypeId, costPlanId, updateCostPlanCosts],
    );
    const pageTitle = costPlanQuery?.data?.type === "BASE_PLAN" ? "Update Base Cost Plan" : "Update Cost Override";
    return (
        <PageLayout
            title={<PageTitle title={pageTitle} />}
            navigation={
                <PageBackButton
                    text={"Back to Plan"}
                    path={`tenants/${tenantId}/networkcredentialtypes/${networkCredentialTypeId}/costplans/${costPlanId}`}
                />
            }
        >
            <Paper withDivider={false} header={undefined} cardProps={{ w: "100%" }}>
                {(costPlanQuery?.isLoading || telcoCostsQuery?.isLoading) && <Loading />}
                {costPlanQuery?.data &&
                    telcoCostsQuery?.data &&
                    (costPlanQuery?.data?.type === "BASE_PLAN" ? (
                        <BaseCostPlanForm
                            onSubmit={handleBaseCostPlanSubmit}
                            defaultValue={{
                                costPlan: costPlanQuery?.data,
                                telcoCosts: telcoCostsQuery?.data?.content,
                            }}
                        />
                    ) : (
                        <CostOverrideCostPlanForm
                            onSubmit={handleCostOverrideSubmit}
                            defaultValue={{
                                costPlan: costPlanQuery?.data,
                                telcoCosts: telcoCostsQuery?.data?.content,
                            }}
                        />
                    ))}
            </Paper>
        </PageLayout>
    );
};

export default UpdateCostPlanPage;
