import React from "react";
import { Currency, DataUnits, Money } from "@cobira/ui-library";
import { Box, HStack, Text } from "@chakra-ui/react";
import { TelcoServiceType } from "../../utils/pricingUtils";

export interface TelcoCostPriceProps {
    cost: number;
    currency: Currency;
    serviceType: TelcoServiceType | string;
}

const TelcoCostPrice = ({ cost, currency, serviceType }: TelcoCostPriceProps) => {
    switch (serviceType) {
        case "SMS_MOBILE_TERMINATED":
        case "SMS_MOBILE_ORIGINATED":
            return <Money unit={"SMS"} currency={currency} value={cost} />;
        case "DATA":
            return <Money unit={[DataUnits.b.unit, DataUnits.mb.unit]} currency={currency} value={cost} />;
        default:
            return (
                <HStack gap={1}>
                    <Box ml={"auto"}>
                        <Money currency={currency} value={cost} />
                    </Box>
                    <Text>/ {serviceType}</Text>
                </HStack>
            );
    }
};

export default TelcoCostPrice;
