import React from "react";
import { Text } from "@chakra-ui/react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";

export interface TenantCellProps {
    tenantId?: string;
}

export const TenantCell = ({ tenantId }: TenantCellProps) => {
    const { tenantApi } = useApi();
    if (!tenantId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => tenantApi.getTenant({ tenantId: tenantId }),
                queryKey: ["tenants", tenantId],
            }}
            render={result => <Text>{result?.data?.name}</Text>}
        />
    );
};
