import React from "react";
import { CobiraTabs, EditIcon, PageBackButton, PageLayout, PageTitle, usePageNavigation } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import TenantNetworkCredentialTypeCostPlanDetailsPanel from "./CostPlanDetailsPanel/TenantNetworkCredentialTypeCostPlanDetailsPanel";
import TenantNetworkCredentialTypeTelcoCostsPanel from "./TelcoCostsPanel/TenantNetworkCredentialTypeTelcoCostsPanel";
import { tab } from "../../../utils/tabUtils";
import { Button } from "@chakra-ui/react";

const TenantCostPlanDetailPage = () => {
    const { tenantApi, configurationApi } = useApi();
    const { tenantId, networkCredentialTypeId, costPlanId } = useParams();
    const { navigate } = usePageNavigation<void>({
        route: () =>
            `/tenants/${tenantId}/networkcredentialtypes/${networkCredentialTypeId}/costplans/${costPlanId}/update`,
    });

    const networkCredentialTypeQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId],
        () =>
            configurationApi.getNetworkCredentialType({
                networkCredentialTypeId: networkCredentialTypeId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!tenantId,
        },
    );

    const costPlanQuery = useQuery(
        ["tenants", tenantId, "networkcredentialtypes", networkCredentialTypeId, "costplans", costPlanId],
        () =>
            tenantApi.getTenantNetworkCredentialTypeCostPlan({
                networkCredentialTypeId: networkCredentialTypeId || "",
                costPlanId: costPlanId || "",
                tenantId: tenantId || "",
            }),
        {
            enabled: !!networkCredentialTypeId && !!costPlanId && !!tenantId,
        },
    );

    const tenantCostPlanDetailTabs = [
        tab("Details", <TenantNetworkCredentialTypeCostPlanDetailsPanel query={costPlanQuery} tenantId={tenantId} />),
        tab(
            "Costs",
            <TenantNetworkCredentialTypeTelcoCostsPanel
                costPlanQuery={costPlanQuery}
                networkCredentialTypeQuery={networkCredentialTypeQuery}
                tenantId={tenantId}
            />,
        ),
    ];

    return (
        <PageLayout
            title={<PageTitle title={costPlanQuery?.data?.name || "Cost Plan"} />}
            navigation={
                <PageBackButton
                    text={"Back to network credential type"}
                    path={`/tenants/${tenantId}/networkcredentialtypes/${networkCredentialTypeId}`}
                />
            }
            pageContext={
                <Button leftIcon={<EditIcon />} onClick={() => navigate()}>
                    Update
                </Button>
            }
        >
            <CobiraTabs tabs={tenantCostPlanDetailTabs} />
        </PageLayout>
    );
};

export default TenantCostPlanDetailPage;
