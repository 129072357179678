import React from "react";
import { z } from "zod";
import { useFormContext } from "react-hook-form";
import { ControlledYearMonthInput, FormContainer, FormField, Month } from "@cobira/ui-library";
import { Box, HStack, Input, Textarea } from "@chakra-ui/react";
import { CostOverrideCostPlanSchemaType } from "./CostOverrideCostPlanForm";

export const CostPlanDetailsSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().optional(),
    appliesFrom: z
        .object({
            year: z.number().gt(2023, "Please select a year after 2023"),
            month: z.nativeEnum(Month),
        })
        .required(),
});

const CostPlanDetailsFormStep = () => {
    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<CostOverrideCostPlanSchemaType>();

    return (
        <FormContainer>
            <HStack gap={4} alignItems={"start"} w={"100%"}>
                <Box w={"75%"}>
                    <FormField label={"Cost Plan Name"} error={errors?.name}>
                        <Input id="name" placeholder="Cost Plan Name" {...register("name")} />
                    </FormField>
                </Box>
                <FormField label={"Applies From"} error={errors?.appliesFrom}>
                    <ControlledYearMonthInput control={control} name={"appliesFrom"} selection={"single"} />
                </FormField>
            </HStack>

            <FormField label={"Description"} error={errors?.description}>
                <Textarea id="description" placeholder="Description" {...register("description")} />
            </FormField>
        </FormContainer>
    );
};

export default CostPlanDetailsFormStep;
