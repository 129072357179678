import { Country, rangeInput, singleInput, usePersistedSearch } from "@cobira/ui-library";
import {
    BillingGroup,
    Customer,
    NetworkCredentialType,
    NetworkState,
    SimCardCapabilityName,
    SimConfiguration,
    UsagePackageType,
} from "../api";
import { NetworkStateLabels } from "../labels/NetworkStateLabels";
import { ConsumptionStateSelect } from "../components/SearchSelectConsumptionState/SearchSelectConsumptionState";
import { SimCardCapabilityNameLabels } from "../labels/SimCardCapabilityNameLabels";

export interface UseSimCardSearchProps {
    onChange?: () => void;
}

export const isMultiImsiFilterLabel = (value: boolean): string => (value ? "Only Multi IMSI" : "Only Single IMSI");

const useSimCardSearch = ({ onChange }: UseSimCardSearchProps) => {
    return usePersistedSearch({
        persistenceKey: "sim-card-table-search",
        config: {
            search: singleInput<string>("Search"),
            iccRange: rangeInput<string>("ICC Range"),
            billingGroup: singleInput<BillingGroup>("Billing Group", { display: value => value.name }),
            customer: singleInput<Customer>("Customer", { display: value => value.customerName }),
            simCardState: singleInput<NetworkState>("SIM Card State", { display: value => NetworkStateLabels[value] }),
            consumptionState: singleInput<ConsumptionStateSelect>("Usage Activity", { display: value => value.label }),
            country: singleInput<Country>("Country", { display: value => `${value.name} (${value.iso2})` }),
            simCapability: singleInput<SimCardCapabilityName>("SIM Capability", {
                display: value => SimCardCapabilityNameLabels[value],
            }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type", {
                display: value => `${value.imsiProvider} (${value.plmn})`,
            }),
            simConfiguration: singleInput<SimConfiguration>("SIM Configuration", {
                display: value => value.name,
            }),
            plmnSearch: singleInput<string>("PLMN Search"),
            isMultiImsi: singleInput<boolean>("Multi IMSI", { display: isMultiImsiFilterLabel }),
            usagePackageType: singleInput<UsagePackageType>("Bundle", { display: value => value.customName }),
            msisdnSearch: singleInput<string>("MSISDN Search"),
        },
        onChange: onChange,
    });
};

export default useSimCardSearch;
