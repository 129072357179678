import { NetworkCredential, OverageState, SimCard } from "../../../../api";
import { ImeiLockList, ImeiValueType, Paper, PhoneNumberList, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { ImeiLockStateLabels } from "../../../../labels/ImeiLockStateLabels";
import { ImeiLockViolationBehaviourLabels } from "../../../../labels/ImeiLockLockViolationBehaviourLabels";
import InlineNetworkCredentialType from "../../../../components/InlineNetworkCredentialType/InlineNetworkCredentialType";
import { OverageStateLabels } from "../../../../labels/OverageStateLabels";

export interface NetworkCredentialDetailsProps {
    networkCredential?: NetworkCredential;
    simCard?: SimCard;
}

const NetworkCredentialDetails = ({ networkCredential, simCard }: NetworkCredentialDetailsProps) => {
    const { simCardApi, configurationApi } = useApi();
    const { data: networkCredentialSettings, isFetching: isLoadingNetworkCredentialSettings } = useQuery(
        ["networkcredentials", networkCredential?.imsi, "settings"],
        () => simCardApi.getNetworkCredentialSettings({ imsi: networkCredential?.imsi || "", icc: simCard?.icc || "" }),
        { enabled: !!networkCredential?.imsi && !!simCard?.icc },
    );
    const { data: networkCredentialImeiLocks, isFetching: isFetchingImeiLocks } = useQuery(
        ["networkcredentials", networkCredential?.imsi, "imeilocks"],
        () =>
            simCardApi.getNetworkCredentialImeiLocks({ imsi: networkCredential?.imsi || "", icc: simCard?.icc || "" }),
        { enabled: !!networkCredential?.imsi && !!simCard?.icc },
    );
    const { data: phoneNumbers, isFetching: isFetchingPhoneNumbers } = useQuery(
        ["networkcredentials", networkCredential?.imsi, "phonenumbers"],
        () =>
            simCardApi.getNetworkCredentialPhoneNumbers({
                imsi: networkCredential?.imsi || "",
                icc: simCard?.icc || "",
            }),
        { enabled: !!networkCredential?.imsi && !!simCard?.icc },
    );
    const { data: networkCredentialType, isFetching: isFetchingNetworkCredentialType } = useQuery(
        ["networkcredentialtypes", networkCredential?.networkCredentialTypeId],
        () =>
            configurationApi.getNetworkCredentialType({
                networkCredentialTypeId: networkCredential?.networkCredentialTypeId || "",
            }),
        { enabled: !!networkCredential?.networkCredentialTypeId },
    );

    const renderContent = () => {
        if (!networkCredential) {
            return <Text>Please Select a Network Credential</Text>;
        }

        return (
            <SimpleGrid columns={4} width={"100%"}>
                <Flex direction={"column"}>
                    <TextColumn heading="Overage" mt={2}>
                        {OverageStateLabels[networkCredential.overageState || OverageState.Undetermined]}
                    </TextColumn>
                    <TextColumn heading="IMEI Lock State" isLoading={isLoadingNetworkCredentialSettings} mt={2}>
                        <PlaceholderText
                            text={ImeiLockStateLabels.getLabel(networkCredentialSettings?.imeiLockState)}
                        />
                    </TextColumn>
                    <TextColumn
                        mt={2}
                        heading={"IMEI Lock Violation Behaviour"}
                        isLoading={isLoadingNetworkCredentialSettings}
                    >
                        <PlaceholderText
                            text={ImeiLockViolationBehaviourLabels.getLabel(
                                networkCredentialSettings?.imeiLockViolationBehaviour,
                            )}
                        />
                    </TextColumn>
                </Flex>
                <Flex direction={"column"}>
                    <TextColumn heading={"Configured IMEIs"}>
                        <ImeiLockList
                            isLoading={isLoadingNetworkCredentialSettings || isFetchingImeiLocks}
                            imeiLocks={networkCredentialImeiLocks?.map(imeiLock => ({
                                allowedImei: imeiLock.allowedImeiValue,
                                allowedImeiType: imeiLock.imeiValueType as ImeiValueType,
                            }))}
                        />
                    </TextColumn>
                </Flex>
                <Flex direction={"column"}>
                    <TextColumn heading={"Phone Numbers"}>
                        <PhoneNumberList
                            isLoading={isLoadingNetworkCredentialSettings || isFetchingPhoneNumbers}
                            phoneNumbers={phoneNumbers}
                        />
                    </TextColumn>
                </Flex>
                <Flex direction={"column"}>
                    <TextColumn heading={"Network Credential Type"} isLoading={isFetchingNetworkCredentialType}>
                        <InlineNetworkCredentialType networkCredentialType={networkCredentialType} />
                    </TextColumn>
                </Flex>
            </SimpleGrid>
        );
    };

    return (
        <Paper header="Network Credential Details" withDivider>
            {renderContent()}
        </Paper>
    );
};

export default NetworkCredentialDetails;
