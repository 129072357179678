/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Currency } from './Currency';
import {
    CurrencyFromJSON,
    CurrencyFromJSONTyped,
    CurrencyToJSON,
} from './Currency';
import type { Markup } from './Markup';
import {
    MarkupFromJSON,
    MarkupFromJSONTyped,
    MarkupToJSON,
} from './Markup';
import type { PostProcessingTiers } from './PostProcessingTiers';
import {
    PostProcessingTiersFromJSON,
    PostProcessingTiersFromJSONTyped,
    PostProcessingTiersToJSON,
} from './PostProcessingTiers';

/**
 * Representation of a node in the telco related rate plan tree.
 * Post processed tiers are applied at the end of the billing cycle, 
 * while the rate markup is applied for each usage record received within the billing cycle
 * 
 * Additionally, it is required to specify the DEFAULT Rate as well as all the available service types.
 * 
 * The supplied currency will only have effect if absolute markup is used
 * @export
 * @interface TelcoRate
 */
export interface TelcoRate {
    /**
     * The `DEFAULT` rate discriminator does not support grouping.
     * All other rate discriminators support mutually exclusive groups.
     * 
     * When the discriminator `SERVICE_TYPE` is used, the valid rate keys are:
     * - `DATA`
     * - `SMS_MOBILE_ORIGINATED`
     * - `SMS_MOBILE_TERMINATED`
     * @type {string}
     * @memberof TelcoRate
     */
    rateDiscriminator: TelcoRateRateDiscriminatorEnum;
    /**
     * 
     * @type {Set<string>}
     * @memberof TelcoRate
     */
    rateKey: Set<string>;
    /**
     * 
     * @type {Markup}
     * @memberof TelcoRate
     */
    rateMarkup: Markup;
    /**
     * 
     * @type {PostProcessingTiers}
     * @memberof TelcoRate
     */
    postProcessedTiers?: PostProcessingTiers;
    /**
     * 
     * @type {Array<TelcoRate>}
     * @memberof TelcoRate
     */
    childRates?: Array<TelcoRate>;
    /**
     * 
     * @type {Currency}
     * @memberof TelcoRate
     */
    currency?: Currency;
}


/**
 * @export
 */
export const TelcoRateRateDiscriminatorEnum = {
    Default: 'DEFAULT',
    ServiceType: 'SERVICE_TYPE',
    Country: 'COUNTRY',
    Network: 'NETWORK'
} as const;
export type TelcoRateRateDiscriminatorEnum = typeof TelcoRateRateDiscriminatorEnum[keyof typeof TelcoRateRateDiscriminatorEnum];


/**
 * Check if a given object implements the TelcoRate interface.
 */
export function instanceOfTelcoRate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rateDiscriminator" in value;
    isInstance = isInstance && "rateKey" in value;
    isInstance = isInstance && "rateMarkup" in value;

    return isInstance;
}

export function TelcoRateFromJSON(json: any): TelcoRate {
    return TelcoRateFromJSONTyped(json, false);
}

export function TelcoRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelcoRate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rateDiscriminator': json['rateDiscriminator'],
        'rateKey': json['rateKey'],
        'rateMarkup': MarkupFromJSON(json['rateMarkup']),
        'postProcessedTiers': !exists(json, 'postProcessedTiers') ? undefined : PostProcessingTiersFromJSON(json['postProcessedTiers']),
        'childRates': !exists(json, 'childRates') ? undefined : ((json['childRates'] as Array<any>).map(TelcoRateFromJSON)),
        'currency': !exists(json, 'currency') ? undefined : CurrencyFromJSON(json['currency']),
    };
}

export function TelcoRateToJSON(value?: TelcoRate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rateDiscriminator': value.rateDiscriminator,
        'rateKey': Array.from(value.rateKey as Set<any>),
        'rateMarkup': MarkupToJSON(value.rateMarkup),
        'postProcessedTiers': PostProcessingTiersToJSON(value.postProcessedTiers),
        'childRates': value.childRates === undefined ? undefined : ((value.childRates as Array<any>).map(TelcoRateToJSON)),
        'currency': CurrencyToJSON(value.currency),
    };
}

