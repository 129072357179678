import React, { useMemo, useState } from "react";
import { TabPanel, VStack } from "@chakra-ui/react";
import { Customer, NetworkCredentialType, UsagePackageType } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import {
    Country,
    CountryInfo,
    getCountries,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    usePageNavigation,
    usePagination,
    usePersistedSearch,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { BooleanLabels } from "../../../../labels/BooleanLabels";
import { mapBooleanFilter } from "../../../../utils/mapperUtils";
import UsagePackageTypeTable from "../../../../components/UsagePackageTypeTable/UsagePackageTypeTable";
import ConfirmDeleteCustomerPackageCatalogueModal from "../../../../components/Modal/ConfirmDeleteCustomerPackageCatalogueModal/ConfirmDeleteCustomerPackageCatalogueModal";
import { SearchSelectNetworkCredentialTypeInput } from "../../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";

interface CustomerPackageCataloguePanelProps {
    query: UseQueryResult<Customer>;
}

const CustomerPackageCataloguePanel = ({ query: { data: customer } }: CustomerPackageCataloguePanelProps) => {
    const { usagePackageApi } = useApi();
    const [customerUsagePackageTypeToDelete, setCustomerUsagePackageTypeToDelete] = useState<
        UsagePackageType | undefined
    >(undefined);
    const { navigate } = usePageNavigation<UsagePackageType>({
        route: value => `/customers/${value.id}/bundles/${value.id}`,
    });
    const countries = useMemo(() => getCountries(), []);
    const { setPageState, resetPageState, pageState } = usePagination({ pageIndex: 0, pageSize: 20 });
    const search = usePersistedSearch({
        persistenceKey: "customer-usage-package-type-catalogue-search",
        config: {
            search: singleInput<string>("Search"),
            country: singleInput<Country>("Country", { display: value => `${value.name} (${value.iso2})` }),
            includeDeleted: singleInput<boolean>("Include Deleted", {
                display: value => BooleanLabels(value),
            }),
            dataPackageEnabled: singleInput<boolean>("Data Enabled", {
                display: value => BooleanLabels(value),
            }),
            smsPackageEnabled: singleInput<boolean>("SMS Enabled", {
                display: value => BooleanLabels(value),
            }),
            assigned: singleInput<boolean>("Assigned to SIM", {
                display: value => BooleanLabels(value),
            }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type", {
                display: value => `${value.imsiProvider}`,
            }),
        },
        onChange: resetPageState,
    });

    const usagePackageTypesQuery = useQuery(
        ["customers", customer?.id, "usagepackagetypes", pageState, search.state],
        () =>
            usagePackageApi.getCustomerUsagePackageTypes({
                customerId: customer?.id || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                search: search.state?.search?.value || undefined,
                country: search.state?.country?.value?.iso2 || undefined,
                includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
                dataPackageEnabled: mapBooleanFilter(search.state?.dataPackageEnabled?.value),
                smsPackageEnabled: mapBooleanFilter(search.state?.smsPackageEnabled?.value),
                usagePackageTypeAssigned: mapBooleanFilter(search.state?.assigned?.value),
                networkCredentialType: search.state?.networkCredentialType?.value?.id,
            }),
        { enabled: customer?.id !== undefined },
    );

    return (
        <>
            <TabPanel w={"100%"}>
                <VStack w={"100%"} gap={2}>
                    <SearchBar
                        defaultFilterInputId={"search"}
                        useSearch={search}
                        filterInputs={[
                            {
                                id: "search",
                                menuLabel: "Search",
                                inputComponent: (
                                    <SearchSingleInput
                                        registration={search.registerInput({ id: "search" })}
                                        placeholder={"Quick find..."}
                                    />
                                ),
                            },
                            {
                                id: "country",
                                menuLabel: "Country",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "country" })}
                                        items={countries}
                                        autocompleteAbleMapping={value => `${value.name} (${value.iso2})`}
                                        displayMapping={value => <CountryInfo country={value} />}
                                    />
                                ),
                            },
                            {
                                id: "includeDeleted",
                                menuLabel: "Include Deleted",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "includeDeleted" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "dataPackageEnabled",
                                menuLabel: "Data Enabled",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "dataPackageEnabled" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "smsPackageEnabled",
                                menuLabel: "SMS Enabled",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "smsPackageEnabled" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "inUse",
                                menuLabel: "Assigned to SIM",
                                inputComponent: (
                                    <SearchSelectInput
                                        registration={search.registerInput({ id: "assigned" })}
                                        items={[true, false]}
                                        autocompleteAbleMapping={value => BooleanLabels(value)}
                                        displayMapping={value => BooleanLabels(value)}
                                    />
                                ),
                            },
                            {
                                id: "networkCredentialType",
                                menuLabel: "Network Credential Type",
                                inputComponent: (
                                    <SearchSelectNetworkCredentialTypeInput
                                        registration={search.registerInput({ id: "networkCredentialType" })}
                                    />
                                ),
                            },
                        ]}
                    />
                    <UsagePackageTypeTable
                        usagePackageTypeQuery={usagePackageTypesQuery}
                        onDelete={setCustomerUsagePackageTypeToDelete}
                        withRowClick={{
                            enableHoverStyle: true,
                            onRowClicked: navigate,
                        }}
                        withPagination={{
                            pageIndex: pageState.pageIndex,
                            pageSize: pageState.pageSize,
                            onPaginationChange: setPageState,
                            totalRowCount: usagePackageTypesQuery?.data?.pageProperties?.totalElements || 0,
                            totalPageCount: usagePackageTypesQuery?.data?.pageProperties?.totalPages || 0,
                        }}
                    />
                </VStack>
            </TabPanel>
            <ConfirmDeleteCustomerPackageCatalogueModal
                onClose={() => setCustomerUsagePackageTypeToDelete(undefined)}
                customer={customer}
                usagePackageType={customerUsagePackageTypeToDelete}
            />
        </>
    );
};

export default CustomerPackageCataloguePanel;
