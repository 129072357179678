/* tslint:disable */
/* eslint-disable */
/**
 * MVNO API
 * API for interacting with the Cobira MVNO Service.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: development@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RadioTechnology } from './RadioTechnology';
import {
    RadioTechnologyFromJSON,
    RadioTechnologyFromJSONTyped,
    RadioTechnologyToJSON,
} from './RadioTechnology';

/**
 * 
 * @export
 * @interface DataUsageRecord
 */
export interface DataUsageRecord {
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    billingGroupId: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    icc: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    imsi: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    networkCredentialTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    usagePackageId?: string;
    /**
     * 
     * @type {number}
     * @memberof DataUsageRecord
     */
    bytes: number;
    /**
     * 
     * @type {number}
     * @memberof DataUsageRecord
     */
    billableBytes: number;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    plmn: string;
    /**
     * 
     * @type {string}
     * @memberof DataUsageRecord
     */
    countryCode: string;
    /**
     * 
     * @type {RadioTechnology}
     * @memberof DataUsageRecord
     */
    radioTechnology?: RadioTechnology;
    /**
     * 
     * @type {Date}
     * @memberof DataUsageRecord
     */
    openedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DataUsageRecord
     */
    closedAt: Date;
}

/**
 * Check if a given object implements the DataUsageRecord interface.
 */
export function instanceOfDataUsageRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "billingGroupId" in value;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "imsi" in value;
    isInstance = isInstance && "networkCredentialTypeId" in value;
    isInstance = isInstance && "bytes" in value;
    isInstance = isInstance && "billableBytes" in value;
    isInstance = isInstance && "plmn" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "openedAt" in value;
    isInstance = isInstance && "closedAt" in value;

    return isInstance;
}

export function DataUsageRecordFromJSON(json: any): DataUsageRecord {
    return DataUsageRecordFromJSONTyped(json, false);
}

export function DataUsageRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataUsageRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'billingGroupId': json['billingGroupId'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'icc': json['icc'],
        'imsi': json['imsi'],
        'networkCredentialTypeId': json['networkCredentialTypeId'],
        'usagePackageId': !exists(json, 'usagePackageId') ? undefined : json['usagePackageId'],
        'bytes': json['bytes'],
        'billableBytes': json['billableBytes'],
        'plmn': json['plmn'],
        'countryCode': json['countryCode'],
        'radioTechnology': !exists(json, 'radioTechnology') ? undefined : RadioTechnologyFromJSON(json['radioTechnology']),
        'openedAt': (new Date(json['openedAt'])),
        'closedAt': (new Date(json['closedAt'])),
    };
}

export function DataUsageRecordToJSON(value?: DataUsageRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'billingGroupId': value.billingGroupId,
        'tenantId': value.tenantId,
        'icc': value.icc,
        'imsi': value.imsi,
        'networkCredentialTypeId': value.networkCredentialTypeId,
        'usagePackageId': value.usagePackageId,
        'bytes': value.bytes,
        'billableBytes': value.billableBytes,
        'plmn': value.plmn,
        'countryCode': value.countryCode,
        'radioTechnology': RadioTechnologyToJSON(value.radioTechnology),
        'openedAt': (value.openedAt.toISOString()),
        'closedAt': (value.closedAt.toISOString()),
    };
}

