import { useApi } from "../../../hooks/useApi";
import { BasicModal, useMetadata, useNotifications } from "@cobira/ui-library";
import { Action, UsagePackageApiCreateUsagePackageTypeRequest } from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { VStack } from "@chakra-ui/react";
import CreateUsagePackageTypeForm, {
    CreateUsagePackageTypeFormSchemaType,
} from "../../../forms/CreateUsagePackageTypeForm/CreateUsagePackageTypeForm";
import { mapDataAllowance, mapSmsAllowance } from "../../../utils/mapperUtils";

export interface CreateUsagePackageTypeModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateUsagePackageTypeModal = ({ isOpen, onClose }: CreateUsagePackageTypeModalProps) => {
    const { usagePackageApi } = useApi();
    const { tenantId } = useMetadata();
    const notifications = useNotifications();
    const queryClient = useQueryClient();

    const createUsagePackageType = useMutation<Array<Action>, Error, UsagePackageApiCreateUsagePackageTypeRequest>(
        variables => usagePackageApi.createUsagePackageType(variables),
        {
            onSuccess: () => {
                notifications.success(`Bundle creation started`);
                queryClient.invalidateQueries(["usagepackagetypes"]);
            },
        },
    );

    const handleSubmit = useCallback(
        (form: CreateUsagePackageTypeFormSchemaType) => {
            createUsagePackageType.mutate({
                createUsagePackageType: {
                    customName: form.name,
                    packageDuration: form.duration,
                    automaticActivationType: form.activationType,
                    dataUsageAllowance: mapDataAllowance(form.dataBundle),
                    smsUsageAllowance: mapSmsAllowance(form.smsBundle),
                    supportedCountries: form.supportedCountries.map(country => country.iso2),
                    networkCredentialTypes: new Set(
                        form.networkCredentialTypes.map(networkCredentialType => networkCredentialType.id),
                    ),
                },
            });
            onClose();
        },
        [createUsagePackageType, onClose],
    );

    return (
        <BasicModal title={`Create Bundle`} onClose={onClose} isOpen={isOpen} modalProps={{ size: "2xl" }}>
            <VStack w={"100%"} align={"stretch"}>
                <CreateUsagePackageTypeForm onSubmit={handleSubmit} onCancel={onClose} forTenantId={tenantId} />
            </VStack>
        </BasicModal>
    );
};

export default CreateUsagePackageTypeModal;
